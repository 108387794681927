import React from "react";
import { Chart } from "react-chartjs-2";
import { graphs } from "styleConstants";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GRAPHS_BORDER_COLORS_MAP, GRAPHS_COLORS_MAP } from "helpers/graphs";
import toast from "react-hot-toast";
import { formatDate } from "helpers/dateFormater";
import axios from "axios";
import { checkResponse } from "helpers/requests";
import capitalize from "helpers/capitalize";
import GraphLoading from "../GraphLoading";
import { useAuthContext } from "contexts/AuthContext/context";

export default function UserTypeSupportsGraph() {
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [months, setMonths] = React.useState<any>(6);
  const [labels, setLabels] = React.useState<Array<string>>([]);
  const [values1, setValues1] = React.useState<Array<number>>([]);
  const [values2, setValues2] = React.useState<Array<number>>([]);
  const [loading, setLoading] = React.useState(false);

  async function getData() {
    try {
      setLoading(true);

      // Make request
      const server = process.env.REACT_APP_SERVER_URL;
      const params = {
        cur_date: formatDate(new Date(), false),
        months,
        securityID: partner?.id,
      };
      const url = `${server}/graphs/supports/user-type`;
      const res = await axios.get(url, { params });

      // Checar respuesta
      if (!checkResponse(res)) throw res;

      // Get data
      const resData = res.data?.data;
      const resLabels: string[] = [];
      const resValues1: number[] = [];
      const resValues2: number[] = [];

      resData.forEach((item: any) => {
        resValues1.push(item.customer);
        resValues2.push(item.worker);
        resLabels.push(
          capitalize(
            new Date(item.ts._seconds * 1000).toLocaleString("default", {
              month: "short",
            })
          )
        );
      });

      setLabels(resLabels);
      setValues1(resValues1);
      setValues2(resValues2);
      setLoading(false);
    } catch (error) {
      console.log("GET SUPPORTS USER TYPE ERROR", error);
      toast.error("Hubo un error obteniendo los datos: Soportes");
    }
  }

  React.useEffect(() => {
    getData();
  }, [months]);

  return (
    <div className={graphs.graphContainer}>
      <div className={graphs.graphsTopContainer}>
        <h1 className={graphs.graphTitle}>Soportes por Mes</h1>
        <FormControl size="small" sx={{ width: "300px", textAlign: "start" }}>
          <InputLabel id="month_select">Periodo</InputLabel>
          <Select
            labelId="month_select"
            label="Periodo"
            value={months}
            onChange={(e) =>
              !Number.isNaN(e.target.value) && setMonths(e.target.value)
            }
          >
            <MenuItem value={6}>Últimos Seis Meses</MenuItem>
            <MenuItem value={12}>Último Año</MenuItem>
            <MenuItem value={24}>Últimos Dos Años</MenuItem>
          </Select>
        </FormControl>
      </div>
      {!loading ? (
        <Chart
          options={{
            plugins: {
              legend: {
                position: "bottom",
              },
            },
          }}
          type="line"
          data={{
            labels,
            datasets: [
              {
                label: "Soportes de Clientes",
                data: values1,
                borderWidth: 2,
                borderColor: GRAPHS_BORDER_COLORS_MAP.green,
                backgroundColor: GRAPHS_COLORS_MAP.green,
              },
              {
                label: "Soportes de Trabajadores",
                data: values2,
                borderWidth: 2,
                borderColor: GRAPHS_BORDER_COLORS_MAP.blue,
                backgroundColor: GRAPHS_COLORS_MAP.blue,
              },
            ],
          }}
        />
      ) : (
        <GraphLoading />
      )}
    </div>
  );
}
