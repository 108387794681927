import { useAppContext } from "contexts/AppContext/context";
import capitalize from "helpers/capitalize";
import { cutDisplayText } from "helpers/string";
import { useNavigate } from "react-router-dom";

// Styles
const styles = {
  container:
    "w-[85%] justify-self-center shadow-bs2 cursor-pointer transition-all duration-300 mb-[50px] hover:shadow-bs3",
  img: "w-full xl:h-[200px] h-[250px] object-cover object-top",
  info: "w-full px-[15px] mt-[10px] text-start mb-[20px]",
  h1: "text-[15px] font-semibold mb-1 mt-5",
  brand: "text-gd text-[14px] mb-[10px]",
  price: "font-medium text-[14px]",
  notAvailable: "text-red-500 font-medium text-[14px]",
};

// Interface
interface ModelCardInterface {
  model: any;
}

// Component
export default function ModelCard({ model }: ModelCardInterface) {
  // Variables
  const { appDispatch } = useAppContext();
  const navigate = useNavigate();

  let available = false;
  Object.values(model.sizes).forEach((val: any) => {
    if (Number(val) >= 1) {
      available = true;
    }
  });
  if (model.sizes_in_brand_store && model.sizes_in_brand_store.length > 0) {
    available = true;
  }

  return (
    <div
      className={styles.container}
      onClick={() => {
        /*
        When a card is clicked the data of that model will be stored in the context in the var
        modelOnView, then it will be redirected to the model view page and the data will be displayed
        */
        appDispatch({ type: "setModelOnView", payload: model });
        navigate("/private/models/view");
      }}
    >
      {/* Image */}
      <img
        src={
          model?.model_imgs &&
          model?.model_imgs.length > 0 &&
          model.model_imgs[0].url
            ? model.model_imgs[0].url
            : "/imgs/no_img.png"
        }
        alt="Modelo"
        className={styles.img}
      />

      {/* Info */}
      <div className={styles.info}>
        <h1 className={styles.h1}>
          {cutDisplayText(capitalize(model.name, false, true), 15) ||
            "Sin información"}
        </h1>
        <p className={styles.brand}>{model.brand || "Sin información"}</p>
        <p className={styles.price}>Créditos: {model.price || " -"}</p>
        <p className={styles.price}>Euros: ${model?.euros || "0"}</p>
        {!available && <p className={styles.notAvailable}>NO DISPONIBLE</p>}
      </div>
    </div>
  );
}
