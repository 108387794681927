import React from "react";
import { Toaster } from "react-hot-toast";

export default function ToasterComp() {
  return (
    <Toaster
      toastOptions={{
        duration: 5000,
        style: {
          fontSize: "16px",
          fontWeight: "400",
        },
        success: {
          style: {
            background: "#EDF7ED",
            color: "#416443",
          },
        },
        error: {
          style: {
            background: "#FDEDED",
            color: "#814C4B",
          },
        },
      }}
    />
  );
}
