import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { deliveryTypeTranslator } from "helpers/translators";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "contexts/AppContext/context";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  fields: {
    item: "mb-[30px] h-[50px] flex flex-col justify-center",
    longItem: "mb-[20px] flex flex-col justify-center",
    label: "font-[400] text-[13px] text-gd mb-[3px]",
    value: "",
    redirectItem: "cursor-pointer hover:text-gd transition-all duration-200",
    redirectLabel: "ml-[25px]",
    redirectIcon: "text-gd",
  },
};

interface ReturnDetailsInterface {
  order: any;
}

export default function ReturnDetails({ order }: ReturnDetailsInterface) {
  const navigate = useNavigate();
  const { appDispatch } = useAppContext();

  if (
    order?.return_id ||
    order?.return_type ||
    order?.return_url ||
    order?.next_order_id
  )
    return (
      <div className="shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px] mt-8">
        <h1 className={styles.sectionTitle}>Datos de Devolución</h1>
        <div className="grid grid-cols-3">
          {order?.return_id && (
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>Albarán</p>
              <p className="text-[11px]">{order?.return_id || "Sin ID"}</p>
            </div>
          )}

          {order?.return_type && (
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>Tipo de Envío</p>
              <p className={styles.fields.value}>
                {deliveryTypeTranslator(order?.return_type || "")}
              </p>
            </div>
          )}

          {order?.return_url && (
            <div
              className={`${styles.fields.item} ${styles.fields.redirectItem}`}
            >
              <a href={order?.return_url} target="_blank" rel="noreferrer">
                <p className={styles.fields.redirectLabel}>
                  Devolución{" "}
                  <span>
                    <KeyboardArrowRightIcon
                      className={styles.fields.redirectIcon}
                    />
                  </span>
                </p>
              </a>
            </div>
          )}

          {order?.next_order_id && (
            <div
              className={`${styles.fields.item} ${styles.fields.redirectItem}`}
              onClick={() => {
                // Clean current order
                appDispatch({
                  type: "setOrderOnView",
                  payload: null,
                });
                navigate(`/private/orders/view/${order?.next_order_id}`);
              }}
            >
              <p className={styles.fields.redirectLabel}>
                P. Siguiente{" "}
                <span>
                  <KeyboardArrowRightIcon
                    className={styles.fields.redirectIcon}
                  />
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    );
  return null;
}
