/*
This function is used to format the strings and capitalize its letters
By default if capitalize only the first letter
*/

const capitalize = (
  text?: string | null | undefined,
  allLetters?: boolean, // If you want to capitalize all letters
  allWords?: boolean // If you want to capitalize the first letter of each word
): string => {
  if (!text) {
    return "";
  }

  if (allLetters) {
    return text.toLocaleUpperCase();
  }

  if (allWords) {
    const words = text.split(" ");
    const capitalizedWords = words.map((word) => {
      return `${word[0].toLocaleUpperCase()}${word.slice(1, word.length)}`;
    });
    return capitalizedWords.join(" ");
  }

  return `${text[0].toLocaleUpperCase()}${text.slice(1, text.length)}`;
};
export default capitalize;
