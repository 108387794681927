import "chart.js/auto";
import TotalUsersGraph from "components/Graphs/Users/TotalUsersGraph";
import { graphs } from "styleConstants";

export default function UsersSection() {
  return (
    <div className={graphs.sectionContainer}>
      <TotalUsersGraph />
    </div>
  );
}
