import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { orderSupportStatus } from "helpers/orderStatus";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from "@mui/material";
import { useAppContext } from "contexts/AppContext/context";
import { orderSupportCreatedByTranslator } from "helpers/translators";
import toast from "react-hot-toast";
import {
  returnCreditsToCustomer,
  supportNextStatus,
  updateOrderStatus,
} from "actions/OrdersActions";
import { orderStatusKeys, supportStatus, supportStatusNext } from "types/Order";
import { validateInputs } from "helpers/requests";
import { useAuthContext } from "contexts/AuthContext/context";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  fields: {
    item: "mb-[30px] h-[50px] flex flex-col justify-center",
    longItem: "mb-[20px] flex flex-col justify-center",
    label: "font-[400] text-[13px] text-gd mb-[3px]",
    value: "",
    redirectItem: "cursor-pointer hover:text-gd transition-all duration-200",
    redirectLabel: "ml-[25px]",
    redirectIcon: "text-gd",
  },
  support: {
    dataLabel: "mr-[20px] text-[#2c2c2c] font-[500] text-[15px] w-[120px]",
    dataValue: "text-[15px] text-gr1",
    item: "flex md:flex-row flex-col md:items-center mb-[10px]",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#df7803",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', sans-serif",
  },
});

interface SupportStepperInterface {
  activeStep: string;
}

export default function SupportStepper({
  activeStep,
}: SupportStepperInterface) {
  const { appState, appDispatch } = useAppContext();
  const { orderOnView: order } = appState;
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [steps, setSteps] = React.useState<Array<any>>([]);
  const [openConfirmStepModal, setOpenConfirmStepModal] = React.useState(false);
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [returnCredits, setReturnCredits] = React.useState<boolean>(false);
  const [amountCredits, setAmountCredits] = React.useState("");
  const [orderStatusChange, setOrderStatusChange] =
    React.useState("continue_order");

  // Create steps
  function createSteps() {
    // Get active step index
    let activeIndex = 0;
    Object.values(supportStatus).forEach((step, i) => {
      if (activeStep === step) {
        activeIndex = i;
        setActiveStepIndex(i);
      }
    });

    // Create steps data
    const stepsData: Array<any> = [];
    Object.values(supportStatus).forEach((step, i) => {
      const stepData: { label: string; completed: boolean } = {
        label: orderSupportStatus(step).stepperLabel,
        completed: i < activeIndex,
      };

      // Add
      stepsData.push(stepData);
    });

    // Set steps
    setSteps(stepsData);
  }

  // Confirm step
  async function confirmStepHandler() {
    // Which step is next
    const newStep: string = supportStatusNext[activeStep]; // Get next step
    let supportDataToUpdate = {}; // Object to update extra support data if needed

    // Return credits
    if (
      order?.support_data?.support_status ===
        supportStatus.customer_in_process &&
      returnCredits
    ) {
      if (!validateInputs([amountCredits]) || parseInt(amountCredits, 10) < 0) {
        toast.error("Cantidad de créditos inválida");
        setOpenConfirmStepModal(false);
        return;
      }
      const res = await returnCreditsToCustomer(
        order?.user_id,
        parseInt(amountCredits, 10),
        partner?.id
      );
      if (!res.success) {
        toast.error(res.msg);
        setOpenConfirmStepModal(false);
        return;
      }
      setOpenConfirmStepModal(false);
      toast.success("Créditos devueltos");

      // Compute returned credits of the order
      const prevReturnedCredits = order?.support_data?.returned_credits;
      supportDataToUpdate = {
        ...supportDataToUpdate,
        returned_credits: prevReturnedCredits
          ? prevReturnedCredits + parseInt(amountCredits, 10)
          : parseInt(amountCredits, 10),
      };
    }

    // Update support data
    const res = await supportNextStatus(
      order?.id,
      activeStep,
      newStep,
      supportDataToUpdate,
      partner?.id
    );
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Soporte actualizado");

    // If order is canceled update support status
    if (orderStatusChange === "cancel_order") {
      const data = {
        order_id: order?.id,
        new_status: orderStatusKeys.order_canceled,
        from: partner?.id,
      };
      const resOrderStatus = await updateOrderStatus(data, partner?.id);
      if (!resOrderStatus.success) {
        toast.error(resOrderStatus.msg);
        return;
      }
    }

    // Update locally new state
    const newSupportData = {
      ...order?.support_data,
      support_status: newStep,
      ...supportDataToUpdate,
    };

    // If support is finished change is in support
    const orderData = { ...order, support_data: newSupportData };
    if (newStep === supportStatus.support_ended) {
      orderData.is_in_support = false;
    }
    // If order was canceled change order status
    if (orderStatusChange === "cancel_order") {
      orderData.status = orderStatusKeys.order_canceled;
    }

    appDispatch({
      type: "setOrderOnView",
      payload: orderData,
    });
    setOpenConfirmStepModal(false);
  }

  // On render create steps
  React.useEffect(() => {
    createSteps();
  }, [order]);

  return (
    <div className="w-full shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px] flex flex-col items-center relative mb-[30px] pb-[40px]">
      {/* Title */}
      <h1
        className={`${styles.sectionTitle} text-[#df7c0b] border-b-[#df7c0b]`}
      >
        Estado de Soporte
      </h1>

      <ThemeProvider theme={theme}>
        <div className="w-full px-[30px] flex md:flex-row flex-col">
          {/* Stepper */}
          <div className="w-[300px] md:mb-[0px] mb-[30px]">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStepIndex} orientation="vertical">
                {steps.slice(0, 7).map((step) => (
                  <Step key={step.label} completed={step.completed}>
                    <StepLabel>{step.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>

          {/* Data Container */}
          <div className="w-full flex flex-col items-start grow text-start mt-[10px] md:mb-[0px] mb-[30px]">
            {/* Title */}
            <div className="flex md:flex-row flex-col">
              <p className="mr-[20px] text-[#df7c0b] font-[500] text-[17px]">
                Estado:
              </p>
              <p className="font-[500] text-[17px] text-gr1">
                {orderSupportStatus(activeStep).actionLabel}
              </p>
            </div>

            {/* Data */}
            <div className="mt-[30px]">
              {order?.support_data?.created_by &&
                order?.support_data?.created_by !== "" && (
                  <div className={styles.support.item}>
                    <p className={styles.support.dataLabel}>Creado por: </p>
                    <p className={styles.support.dataValue}>
                      {orderSupportCreatedByTranslator(
                        order?.support_data?.created_by
                      )}
                    </p>
                  </div>
                )}
              {order?.support_data?.contact_method &&
                order?.support_data?.contact_method !== "" && (
                  <div className={styles.support.item}>
                    <p className={styles.support.dataLabel}>Método: </p>
                    <p className={styles.support.dataValue}>
                      {order?.support_data?.contact_method}
                    </p>
                  </div>
                )}
              {order?.support_data?.contact_value &&
                order?.support_data?.contact_value !== "" && (
                  <div className={styles.support.item}>
                    <p className={styles.support.dataLabel}>Contacto: </p>
                    <p className={styles.support.dataValue}>
                      {order?.support_data?.contact_value}
                    </p>
                  </div>
                )}
              {order?.support_data?.message &&
                order?.support_data?.message !== "" && (
                  <div className={styles.support.item}>
                    <p className={styles.support.dataLabel}>Notas: </p>
                    <p className={styles.support.dataValue}>
                      {order?.support_data?.message}
                    </p>
                  </div>
                )}
            </div>

            {order?.support_data?.support_status ===
              supportStatus.customer_in_process && (
              <>
                {/* Divider */}
                <div className="border-t-[1px] border-gray-300 border-solid w-full h-[1px] mt-3" />

                {/* Return Credits */}
                <div className="mt-4 flex flex-col">
                  <FormControlLabel
                    control={<Switch />}
                    value={returnCredits}
                    onChange={() => setReturnCredits(!returnCredits)}
                    label="Devolver Créditos a Cliente"
                    sx={{ color: "#2c2c2c" }}
                  />
                  {returnCredits && (
                    <TextField
                      label="Créditos"
                      type="number"
                      size="small"
                      sx={{ mt: 2, mb: 1.5 }}
                      value={amountCredits}
                      onChange={(e) => setAmountCredits(e.target.value)}
                    />
                  )}
                </div>

                {/* Divider */}
                <div className="border-t-[1px] border-gray-300 border-solid w-full h-[1px] mt-3" />

                {/* Select order status */}
                <RadioGroup
                  sx={{ mt: 2 }}
                  defaultValue="continue_order"
                  value={orderStatusChange}
                  onChange={(e) => setOrderStatusChange(e.target.value)}
                >
                  <FormControlLabel
                    value="continue_order"
                    control={<Radio />}
                    label="Continuar Pedido"
                  />
                  <FormControlLabel
                    value="cancel_order"
                    control={<Radio />}
                    label="Cancelar Pedido"
                  />
                </RadioGroup>
              </>
            )}

            {/* Button */}
            {orderSupportStatus(activeStep).nextBtnLabel && (
              <div className="mt-[30px]">
                <Button
                  variant="contained"
                  onClick={() => setOpenConfirmStepModal(true)}
                >
                  {returnCredits && "Devolver Créditos y "}
                  {orderSupportStatus(activeStep).nextBtnLabel}
                </Button>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>

      {/* Confirm Dialog */}
      <Dialog
        open={openConfirmStepModal}
        onClose={() => setOpenConfirmStepModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">
            {orderSupportStatus(activeStep).confirm?.title}
            {returnCredits && " y Devolver Créditos"}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1">
              {orderSupportStatus(activeStep).confirm?.text}
            </p>
            {returnCredits && (
              <p className="text-red-700 mt-1">
                {`- Se devolverán ${amountCredits} créditos al cliente`}
              </p>
            )}
            {orderStatusChange === "cancel_order" && (
              <p className="text-red-700 mt-1">- La orden se cancelará</p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setOpenConfirmStepModal(false)}>
            Cancelar
          </Button>
          <Button onClick={confirmStepHandler} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
