import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Topbar from "./Topbar";

// Animation Type
interface AnimationType {
  initial?: object;
  animate?: object;
}

// Interface
interface PageLayoutInterface {
  title: string;
  Page: React.FC;
  animation?: AnimationType;
}

// Component
export default function PageLayout(props: PageLayoutInterface) {
  // Props
  const { title, Page, animation } = props;
  const [smallView] = React.useState(
    window.matchMedia("(max-width: 1024px)").matches
  );
  const [showNavbar, setShowNavbar] = React.useState(false);
  const location = useLocation();

  return (
    <div className="h-[100vh] flex">
      {/* Navbar */}
      <Navbar
        smallView={smallView}
        showNavbar={showNavbar}
        setShowNavbar={setShowNavbar}
      />

      <div className="w-full h-[100vh]">
        {/* Title */}
        <Topbar
          title={title}
          smallView={smallView}
          setShowNavbar={setShowNavbar}
        />

        {/* Page */}
        <motion.div
          className="layout-page-conainer w-full pt-[30px] flex flex-col items-center overflow-y-scroll"
          key={location.pathname}
          initial={animation?.initial || { opacity: 0 }}
          animate={animation?.animate || { opacity: 1 }}
        >
          <Page />
        </motion.div>
      </div>
    </div>
  );
}

PageLayout.defaultProps = {
  animation: null,
};
