import React from "react";
import { booleanTranslate } from "helpers/translators";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { daysDifference } from "helpers/dateFormater";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  fields: {
    item: "mb-[30px] h-[50px] flex flex-col justify-center",
    longItem: "mb-[20px] flex flex-col justify-center",
    label: "font-[400] text-[13px] text-gd mb-[3px]",
    value: "",
    redirectItem: "cursor-pointer hover:text-gd transition-all duration-200",
    redirectLabel: "ml-[25px]",
    redirectIcon: "text-gd",
  },
};

interface OrderDetailsInterface {
  order: any;
}

export default function OrderDetails({ order }: OrderDetailsInterface) {
  // Variables
  const [showAddress, setShowAddress] = React.useState(false);

  return (
    <div className="shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px]">
      <h1 className={styles.sectionTitle}>Datos de Pedido</h1>
      <div className="grid grid-cols-3">
        <div className={styles.fields.item}>
          <p className={styles.fields.label}>Créditos</p>
          <p className={styles.fields.value}>
            {order?.total_credits || "Sin créditos"}
          </p>
        </div>
        <div className={styles.fields.item}>
          <p className={styles.fields.label}>Fecha de Pedido</p>
          <p className={styles.fields.value}>
            {new Date(order?.date_created._seconds * 1000)
              .toLocaleString()
              .substring(0, 9) || "Sin fecha"}
          </p>
        </div>

        <div className={styles.fields.item}>
          <p className={styles.fields.label}>En Soporte</p>
          <p className={styles.fields.value}>
            {booleanTranslate(order?.is_in_support) || "Sin créditos"}
          </p>
        </div>

        {order?.end_of_cycle && (
          <div className={styles.fields.item}>
            <p className={styles.fields.label}>Fin del Ciclo</p>
            <p className={styles.fields.value}>
              {new Date(order?.end_of_cycle._seconds * 1000)
                .toLocaleString()
                .substring(0, 9) || "Sin fecha"}
            </p>
          </div>
        )}

        {order?.end_of_cycle && (
          <div className={styles.fields.item}>
            <p className={styles.fields.label}>Días Restantes</p>
            <p className={styles.fields.value}>
              {daysDifference(
                new Date(),
                new Date(order.end_of_cycle._seconds * 1000)
              )}{" "}
              días
            </p>
          </div>
        )}

        <div className={`${styles.fields.item} ${styles.fields.redirectItem}`}>
          <p className={styles.fields.redirectLabel}>
            Cliente{" "}
            <span>
              <KeyboardArrowRightIcon className={styles.fields.redirectIcon} />
            </span>
          </p>
        </div>

        <div
          className={`${styles.fields.item} ${styles.fields.redirectItem}`}
          onClick={() => setShowAddress((curValue) => !curValue)}
        >
          <p className={styles.fields.redirectLabel}>
            Dirección{" "}
            <span>
              <KeyboardArrowRightIcon className={styles.fields.redirectIcon} />
            </span>
          </p>
        </div>
      </div>

      {/* Address Data */}
      {showAddress && (
        <>
          <h1 className={styles.sectionTitle}>Dirección de Envío</h1>
          <div className={styles.fields.longItem}>
            <p className={styles.fields.label}>Calle</p>
            <p className={styles.fields.value}>
              {order?.address?.street || "-"}
            </p>
          </div>
          <div className={styles.fields.longItem}>
            <p className={styles.fields.label}>Descripción</p>
            <p className={styles.fields.value}>
              {order?.address?.description || "-"}
            </p>
          </div>
          <div className="grid grid-cols-4 mt-[30px]">
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>Tag</p>
              <p className={styles.fields.value}>
                {order?.address?.tag || "-"}
              </p>
            </div>
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>Código Postal</p>
              <p className={styles.fields.value}>
                {order?.address?.postal_code || "-"}
              </p>
            </div>
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>No. Exterior</p>
              <p className={styles.fields.value}>
                {order?.address?.exterior_number || "-"}
              </p>
            </div>
            <div className={styles.fields.item}>
              <p className={styles.fields.label}>No. Interior</p>
              <p className={styles.fields.value}>
                {order?.address?.interior_number || "-"}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
