/*
This functions are used to encrypt and decrypt information with a specific password
In the code they are used to encrypt and decrypt the user information and save them in 
local storage to have a persistent auth session.

https://parzibyte.github.io/ejemplos-javascript/encriptacion-js/
*/

const bufferABase64 = (buffer) =>
  btoa(String.fromCharCode(...new Uint8Array(buffer)));
const base64ABuffer = (buffer) =>
  Uint8Array.from(atob(buffer), (c) => c.charCodeAt(0));

const derivacionDeClaveBasadaEnContraseña = async (
  contraseña,
  sal,
  iteraciones,
  longitud,
  hash,
  algoritmo = "AES-CBC"
) => {
  const encoder = new TextEncoder();
  const keyMaterial = await window.crypto.subtle.importKey(
    "raw",
    encoder.encode(contraseña),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  );
  return window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: encoder.encode(sal),
      iterations: iteraciones,
      hash,
    },
    keyMaterial,
    { name: algoritmo, length: longitud },
    false,
    ["encrypt", "decrypt"]
  );
};

export const encriptar = async (contraseña, textoPlano) => {
  const encoder = new TextEncoder();
  const sal = window.crypto.getRandomValues(new Uint8Array(16));
  const vectorInicializacion = window.crypto.getRandomValues(
    new Uint8Array(16)
  );
  const bufferTextoPlano = encoder.encode(textoPlano);
  const clave = await derivacionDeClaveBasadaEnContraseña(
    contraseña,
    sal,
    100000,
    256,
    "SHA-256"
  );
  const encrypted = await window.crypto.subtle.encrypt(
    { name: "AES-CBC", iv: vectorInicializacion },
    clave,
    bufferTextoPlano
  );
  return bufferABase64([
    ...sal,
    ...vectorInicializacion,
    ...new Uint8Array(encrypted),
  ]);
};

export const desencriptar = async (contraseña, encriptadoEnBase64) => {
  const LONGITUD_SAL = 16;
  const LONGITUD_VECTOR_INICIALIZACION = LONGITUD_SAL;
  const decoder = new TextDecoder();
  const datosEncriptados = base64ABuffer(encriptadoEnBase64);
  const sal = datosEncriptados.slice(0, LONGITUD_SAL);
  const vectorInicializacion = datosEncriptados.slice(
    0 + LONGITUD_SAL,
    LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION
  );
  const clave = await derivacionDeClaveBasadaEnContraseña(
    contraseña,
    sal,
    100000,
    256,
    "SHA-256"
  );
  const datosDesencriptadosComoBuffer = await window.crypto.subtle.decrypt(
    { name: "AES-CBC", iv: vectorInicializacion },
    clave,
    datosEncriptados.slice(LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION)
  );
  return decoder.decode(datosDesencriptadosComoBuffer);
};
