import React from "react";
import { divs } from "styleConstants";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TopData from "../../components/Graphs/TopData";
import UsersSection from "./Sections/UsersSection";
import SubscriptionsSection from "./Sections/SubscriptionsSection";
import ProductsSection from "./Sections/ProductsSection";
import SupportsSection from "./Sections/SupportsSection";

const graphsSections = [
  { title: "Usuarios", key: "users" },
  { title: "Suscripciones", key: "subscriptions" },
  { title: "Modelos y Productos", key: "products" },
  { title: "Soportes y Problemas", key: "supports" },
];

// Component
export default function GraphsAndData() {
  // Variables
  const [section, setSection] = React.useState(graphsSections[0]);

  function renderSection() {
    switch (section.key) {
      case "users":
        return <UsersSection />;
      case "subscriptions":
        return <SubscriptionsSection />;
      case "products":
        return <ProductsSection />;
      case "supports":
        return <SupportsSection />;
      default:
        return <UsersSection />;
    }
  }

  return (
    <div className={`${divs.pageContainer} lg:w-full w-[90%]`}>
      {/* Top Data */}
      <TopData />

      {/* Title and Select */}
      <div className="flex justify-between px-[10px] items-end mb-[50px] border-b-[1px] border-solid border-gd pb-[10px] w-[99%]">
        <h1 className="text-gd text-[24px] font-[600] leading-[24px]">
          Gráficas de {section.title}
        </h1>
        <FormControl size="small" sx={{ width: "200px", textAlign: "start" }}>
          <InputLabel id="section_select">Gráficas</InputLabel>
          <Select
            labelId="section_select"
            label="Gráficas"
            value={section.key}
            onChange={(e) => {
              const selected = graphsSections.find(
                (sec) => sec.key === e.target.value
              );
              if (selected) setSection(selected);
            }}
          >
            {graphsSections.map((item) => (
              <MenuItem value={item.key} key={item.key}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Section */}
      {renderSection()}
    </div>
  );
}
