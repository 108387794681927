import { ButtonEl } from "styledComponents/button";

export default function PageNotFound() {
  return (
    <div>
      <h1 className=" my-[35%] md:my-[5%] md:text-[30px]">
        La página que estas buscando no existe{" "}
      </h1>
      <div className="w-full flex flex-col items-center">
        <img
          className="w-[80%] md:w-[30%] mt-[5%]"
          src="/imgs/404.svg"
          alt="No Hay Resultados"
        />
        <ButtonEl
          onClick={() => {
            window.location.href = "/";
          }}
          className="mt-[10%]"
        >
          Ir a inicio
        </ButtonEl>
      </div>
    </div>
  );
}
