import React from "react";

export default function LoadingComponent() {
  return (
    <div className="w-full flex justify-center h-[400px] items-center">
      <img
        className="w-[100px] h-[100px]"
        src="/imgs/loading.gif"
        alt="Cargando"
      />
    </div>
  );
}
