import { Button, IconButton } from "@mui/material";
import BackButton from "components/General/BackButton";
import React, { useRef } from "react";
import { divs, texts } from "styleConstants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import toast from "react-hot-toast";
import { addModelImgs, getModelById } from "actions/ModelsActions";
import { useAppContext } from "contexts/AppContext/context";
import { useNavigate } from "react-router-dom";
import { uploadMultipleImages } from "helpers/images";
import { useAuthContext } from "contexts/AuthContext/context";

export default function AddImages() {
  // Variables
  const { appState, appDispatch } = useAppContext();
  const { modelOnView, initialModels } = appState;
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [imgs, setImgs] = React.useState<Array<File>>([]);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const [disableBtn, setDisableBtn] = React.useState(false);

  // Add images
  async function addImages() {
    setDisableBtn(true);

    // Validate imgs
    if (!imgs || imgs?.length <= 0) {
      toast.error("No hay imágenes seleccionadas");
      setDisableBtn(false);
      return;
    }

    // Upload images and get refs
    const resUpload = await uploadMultipleImages(imgs);
    if (!resUpload.status) {
      toast.error(resUpload?.msg || "Hubo un error subiendo las imágenes");
      setDisableBtn(false);
      navigate("/private/models/view");
      return;
    }
    const imgsData = resUpload?.fileRefs?.map((img) => ({
      filePath: img?.fullPath,
      fileName: img?.name,
    }));

    // Add images to model
    const res = await addModelImgs(
      modelOnView?.model_id,
      imgsData,
      partner?.id
    );
    if (!res.success) {
      toast.error(res?.msg);
      setDisableBtn(false);
      navigate("/private/models/view");
      return;
    }
    toast.success("Se han añadieron las imágenes correctamente");

    // Get model data and set it in context
    const modelRes = await getModelById(modelOnView?.model_id);
    if (!modelRes.success) {
      toast.error(modelRes.msg);
      return;
    }
    appDispatch({ type: "setModelOnView", payload: modelRes.model }); // Set modelOnView
    if (initialModels) {
      // Delete model from context
      const fiteredModels = initialModels?.filter(
        (m) => m.model_id !== modelOnView?.model_id
      );
      // Add new model data again
      appDispatch({
        type: "setInitialModels",
        payload: [...fiteredModels, modelRes.model],
      });
    }
    setDisableBtn(false);
    navigate("/private/models/view"); // Go to the view of that model
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models/images/view" />
        <h1 className={texts.pageTitle.h1}>Añadir Imágenes del Modelo</h1>
      </div>

      {/* Actions */}
      <div className="md:w-[78%] w-full flex md:justify-between justify-center items-center mb-[15px] mt-[10px]">
        <p className="text-gr1 text-[15px] md:block hidden">
          Añade las imágenes que desees.
        </p>
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }} // It doesn't display
          accept="image/*"
          onChange={(e) => {
            if (imgs && e?.target?.files && e?.target?.files[0]) {
              setImgs([...imgs, e.target.files[0]]); // Add input image
            }
          }}
        />
        <div className="flex">
          <Button
            variant="outlined"
            onClick={() => {
              // @ts-ignore
              inputRef.current.click(); // Open file input
            }}
            size="small"
            startIcon={<AddOutlinedIcon />}
          >
            Añadir Imágen
          </Button>
          <Button
            sx={{ ml: 1.5 }}
            variant="contained"
            size="small"
            onClick={addImages}
            disabled={disableBtn}
          >
            Guardar Cambios
          </Button>
        </div>
      </div>

      {/* Images Contnainer */}
      <div className={divs.modelImagesPage.container}>
        <div className={divs.modelImagesPage.grid}>
          {imgs?.map((img: File) => (
            <div className={divs.modelImagesPage.item} key={img.name}>
              <IconButton
                className={divs.modelImagesPage.deleteBtn}
                onClick={() => {
                  const filteredImages = imgs.filter(
                    (i) => i.name !== img.name
                  );
                  setImgs(filteredImages);
                }}
              >
                <HighlightOffIcon />
              </IconButton>
              <img
                src={URL.createObjectURL(img) || "/imgs/no_img.png"}
                alt="Imagen de Modelo"
                className={divs.modelImagesPage.img}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
