export const divs = {
  pageContainer: "w-full mb-[50px] max-w-[900px] flex flex-col items-center",
  dataContainer:
    "mt-[50px] md:w-[600px] w-full flex md:justify-between md:flex-row items-center flex-col flex-wrap mb-[30px]",
  dataItem: "w-[250px] text-start mb-[30px]",
  inputsContainer: "w-[300px] mt-[30px] flex flex-col mb-[30px]",
  modelImagesPage: {
    container: "flex md:w-full w-[95%] justify-center",
    grid: "md:w-[700px] w-full grid md:grid-cols-3 grid-cols-2 gap-[15px] mt-[20px]",
    item: "w-full h-[220px] relative",
    img: "w-full h-full object-cover object-top shadow-bs4",
    deleteBtn: "delete-model-img-btn top-[2px] right-[2px] text-gd",
  },
};

export const texts = {
  pageTitle: {
    container:
      "border-b-[1px] border-solid border-gd xl:w-full w-[90%] flex items-center mb-[30px]",
    h1: "text-start md:text-[25px] text-[20px] font-medium ml-[10px]",
  },
  dataLabel:
    "text-[14px] font-semibold mb-[10px] text-gd border-b-[1px] border-solid border-gr3 pb-[3px]",
  dataValue: "text-[17px]",
  inputLabel: "text-[14px] text-gd text-start mb-[2px]",
};

export const loginPages = {
  img: "object-cover h-full md:w-[55%] sm:w-[40%] sm:block hidden",
  rightSide: "grow flex justify-center items-center p-[30px] sm:p-[0px]",
  formContainer: "max-w-[300px] flex flex-col",
  h1: "text-start text-[32px] font-bold text-gd mb-[40px] border-b-gd border-b-[1px] border-solid leading-[40px]",
  h2: "text-start text-[23px] font-bold mb-[15px] text-[#272727]",
  goTo: "mt-[5px] text-[13px]",
  staticInfo: "flex text-start text-[15px]",
  label: "mb-[10px] mr-[10px] font-medium text-gd",
};

export const searchBar = {
  container:
    "md:w-[350px] w-full h-[40px] bg-[#f3f3f3] flex rounded-[5px] shadow-bs1 mr-[20px]",
  input:
    "grow h-full py-[5px] px-[15px] text-[15px] outline-none border-none bg-transparent text-gr1",
  button:
    "h-full px-[10px] bg-transparent outline-none border-none cursor-pointer text-gr1",
  icon: "text-gd text-[22px] mt-[4px]",
};

export const navbar = {
  navbar:
    "lg:w-[20%] lg:min-w-[220px] lg:max-w-[250px] h-full flex bg-[#f5f5f5] shadow-bs1 lg:static fixed z-[100] w-full",
  routes: "w-full mt-[30px] flex flex-col items-center",
  route:
    "route group flex w-full h-[40px] mb-[10px] text-start items-center text-[15px] font-medium cursor-pointer transition-all duration-200 hover:bg-[#e9e9e9] hover:text-[#000] lg:pl-[0px] pl-[20px]",
  activeRoute: "bg-[#28924b] text-[#ffffff]",
  icon: "icon ml-[20px] mr-[15px] text-[#268b47] group-hover:text-gd",
};

export const table = {
  container:
    "xl:w-full w-[900px] border-collapse border-[1px] border-solid border-gr6 shadow-bs2",
  row: "min-h-[60px] text-start border-b-[1px] border-solid border-gr6",
  cell: "py-[10px] px-[15px] text-start",
  th: "bg-[#f7f7f7] font-[600] text-[14px]",
  td: "text-[14px]",
  img: "w-[70px] h-[70px] object-contain",
  detailsBtn:
    "flex p-[3px] rounded-[50%] border-none bg-white cursor-pointer hover:opacity-80",
  icon: "text-gd",
  fields: {
    asigned: "w-[200px] font-[400] flex items-center min-h-[59px]",
    date: "text-gr2",
    success: "text-[#1b5e2d]",
    error: "text-[#a71d1d]",
  },
};

export const graphs = {
  sectionContainer: "w-full flex flex-col items-center",
  graphContainer:
    "w-[80%] shadow-bs4 px-[30px] py-[35px] rounded-[20px] mb-[50px] flex flex-col items-center",
  graphsTopContainer: "w-full flex justify-between items-start mb-[25px]",
  graphTitle: "w-full text-start font-[500] text-[20px] px-[20px]",
  graphSmallBox: "w-[350px]",
};
