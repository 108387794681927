import React from "react";
import { AuthActionType } from "contexts/AuthContext/reducer";
import { PartnerType } from "types/Partner";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import { auth } from "firebaseConfig";
import { desencriptar, encriptar } from "helpers/encrypt";
import toast from "react-hot-toast";
import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

// Login
export async function login(
  authDispatch: React.Dispatch<AuthActionType>,
  email: string,
  password: string
) {
  try {
    // Authenticate
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const uid = userCredentials?.user?.uid;

    // Get user data
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/partners/${uid}`;
    const resPartner = await axios.get(url);

    // If partner doesnt exists
    if (resPartner.data.status !== "success")
      throw new Error("No se encontró el socio");

    const partner = {
      id: resPartner.data.data.id,
      name: resPartner.data.data.name,
      email: resPartner.data.data.email,
      user_img: resPartner.data.data.user_img,
      phone: resPartner.data.data.phone,
      user_img_bucket_path: resPartner.data.data.user_img_bucket_path,
    };

    // Save partner in local storage
    const lsKey = "password";
    const partnerEncoded = await encriptar(lsKey, partner.id);
    localStorage.setItem("xerfer-partner", partnerEncoded);

    // Set partner in context
    authDispatch({ type: "login", payload: partner });
  } catch (error: any) {
    console.log("LOGIN ERROR:", error);

    // Auth errors
    switch (error.code) {
      case "auth/invalid-email":
        toast.error("El correo es inválido");
        return;
      case "auth/user-not-found":
        toast.error("No hay una cuenta registrada con ese correo");
        return;
      case "auth/invalid-password":
        toast.error("La contraseña no es correcta");
        return;
      case "auth/wrong-password":
        toast.error("La contraseña no es correcta");
        return;
      default:
        break;
    }

    // Data errors
    toast.error(
      getResponseError(error, "Hubo un error ingresando a la cuenta")
    );
  }
}

// Register
export async function register(
  authDispatch: React.Dispatch<AuthActionType>,
  name: string,
  email: string,
  password: string,
  phone: string
) {
  try {
    // Authenticate
    const userCredentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const uid = userCredentials?.user?.uid;

    // Create new document object
    const partner: PartnerType = {
      id: uid,
      name,
      email,
      phone,
      user_img: null,
      user_img_bucket_path: null,
    };

    // Set new partner in db
    const server = process.env.REACT_APP_SERVER_URL;
    let url = `${server}/partners/new`;
    const res = await axios.post(url, partner);

    // Check response
    if (!checkResponse(res)) throw res;

    // Save partner in local storage
    const lsKey = "password";
    const partnerEncoded = await encriptar(lsKey, partner.id);
    localStorage.setItem("xerfer-partner", partnerEncoded);

    // Set partner in context
    authDispatch({ type: "login", payload: partner });

    // Delete all the new user keys with this email
    url = `${server}/new-user-keys/${partner.email}`;
    await axios.delete(url);
  } catch (error: any) {
    console.log("REGISTER ERROR:", error.code);

    // Auth errors
    switch (error.code) {
      case "auth/uid-already-exists":
        toast.error("El id de usuario ya fue usado por otra cuenta");
        return;
      case "auth/email-already-in-use":
        toast.error("Ya hay una cuenta usando el correo ingresado");
        return;
      case "auth/weak-password":
        toast.error("La contraseña debe tener al menos 6 caracteres");
        return;
      case "auth/invalid-email":
        toast.error("Correo inválido");
        return;
      default:
        break;
    }

    // Data errors
    toast.error(getResponseError(error, "Hubo un error creando la cuenta"));
  }
}

// Logout
export function logout(authDispatch: React.Dispatch<AuthActionType>) {
  // Remove partner in context
  authDispatch({ type: "logout" });

  // Remove partner from local storage
  localStorage.removeItem("xerfer-partner");
}

// Get partner by id
export async function getPartnerById(id: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/partners/${id}`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return models
    return {
      success: true,
      partner: res?.data?.data,
    };
  } catch (error) {
    console.log("GET PARTNER ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un problema obteniendo los datos del socio"
      ),
    };
  }
}

/* 
Check for a logged partner
This function searches for a partner in the local storage and if found, it will decrypt it it and 
set it in the context
*/
export async function checkLoggedPartner(
  authDispatch: React.Dispatch<AuthActionType>
) {
  // Search partner in local storage
  const partner = localStorage.getItem("xerfer-partner");

  // If there is a partner
  if (partner) {
    // Decode partner
    const lsKey = "password"; // This password will be hidden in the future
    const partnerId = await desencriptar(lsKey, partner);

    // Get partner data
    const res = await getPartnerById(partnerId);
    if (res.success) {
      authDispatch({ type: "login", payload: res.partner }); // Login with partner data
    }
  }
}

// Edit partner data
export async function editPartnerData(
  id: string | null | undefined,
  name: string | null | undefined,
  phone: string | null | undefined,
  newImgData?: {
    filePath: string | undefined;
    fileName: string | undefined;
  } | null
) {
  try {
    // Create body
    const body = { name, phone, new_img_data: newImgData };

    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/partners/${id}`;
    const res = await axios.put(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return success
    return { success: true };
  } catch (error) {
    console.log("EDIT PARTNER DATA ERROR: ", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un problema actualizando los datos del socio"
      ),
    };
  }
}

// Change password
export async function changePassword(
  email: string | null | undefined,
  curPassword: string,
  newPassword: string,
  confirmPassword: string
) {
  try {
    // Check email
    if (!email) {
      return {
        success: false,
        msg: "Correo no válido, porfavor vuelve a iniciar sesión",
      };
    }

    // Validate current password
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(email, curPassword);

    // Check email
    if (!user) {
      return {
        success: false,
        msg: "Hubo un error cambiando la contraseña",
      };
    }
    await reauthenticateWithCredential(user, credential);

    // Confirm password
    if (newPassword !== confirmPassword) {
      return {
        success: false,
        msg: "La nueva contraseña y la confirmación no coinciden",
      };
    }

    // Update password
    await updatePassword(user, newPassword);

    // Success
    return { success: true };
  } catch (error: any) {
    console.log("CHANGE PASSWORD ERROR: ", error);
    const response = {
      success: false,
      msg: "Hubo un error cambiando la contraseña",
    };

    // Auth errors
    switch (error.code) {
      case "auth/invalid-email":
        response.msg = "El correo es inválido";
        break;
      case "auth/user-not-found":
        response.msg = "No hay una cuenta registrada con ese correo";
        break;
      case "auth/invalid-password":
        response.msg = "La contraseña no es correcta";
        break;
      case "auth/wrong-password":
        response.msg = "La contraseña no es correcta";
        break;
      case "auth/weak-password":
        response.msg = "La contraseña debe tener al menos 6 caracteres";
        break;
      default:
        break;
    }
    return response;
  }
}
// Verify if there is a key and email to create a new Partner Account
export async function verifyNewPartnerEmailAndKey(key: string) {
  const server = process.env.REACT_APP_SERVER_URL;
  const url = `${server}/new-user-keys/${key}`;
  try {
    const res = await axios.get(url);
    return res.data.data ?? false;
  } catch (e) {
    return false;
  }
}
