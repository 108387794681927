import "chart.js/auto";
import ProcedureTypeSupportsGraph from "components/Graphs/Supports/ProcedureTypeSupportsGraph";
import UserTypeSupportsGraph from "components/Graphs/Supports/UserTypeSupportsGraph";
import { graphs } from "styleConstants";

export default function SupportsSection() {
  return (
    <div className={graphs.sectionContainer}>
      <UserTypeSupportsGraph />
      <ProcedureTypeSupportsGraph />
    </div>
  );
}
