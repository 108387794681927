import { getResponseError, validateInputs } from "helpers/requests";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "firebaseConfig";
import ToasterComp from "components/General/Toaster";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function ResetPassword() {
  // Variables
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);

  // Send reset email
  async function sendResetEmail() {
    try {
      // Validate email
      if (
        !validateInputs([email]) ||
        !(email.includes("@") && email.includes("."))
      ) {
        toast.error("Correo inválido");
        return;
      }

      // Send email
      await sendPasswordResetEmail(auth, email);

      // Success
      setOpenModal(true);
    } catch (error: any) {
      console.log("SEND RESET EMAIL", error);

      // Auth errors
      switch (error.code) {
        case "auth/invalid-email":
          toast.error("El correo es inválido");
          return;
        case "auth/user-not-found":
          toast.error("No hay ningún usuario registrado con ese correo");
          return;
        default:
          toast.error(
            getResponseError(error, "Hubo un error enviando el correo")
          );
          break;
      }
    }
  }

  // On close modal
  function onCloseModal() {
    setOpenModal(false);
    navigate("/login");
  }

  return (
    <div className="w-full h-[100vh] flex justify-center">
      <div className="flex flex-col items-center py-[20px] px-[30px] w-[30%] h-[260px] mt-[10%] shadow-[0px_2px_15px_#b0b0b0] rounded-[10px]">
        {/* Title */}
        <h1 className="mt-[10px] mb-[30px] text-gd font-[500] text-[19px]">
          Restablece tu Contraseña
        </h1>

        {/* Input */}
        <InputEl>
          <p>Ingresa el correo electrónico</p>
          <div>
            <input
              placeholder="Escribe aquí"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </InputEl>

        {/* Btn */}
        <ButtonEl className="w-[170px]" onClick={sendResetEmail}>
          Enviar Correo
        </ButtonEl>
      </div>

      {/* Toaster */}
      <ToasterComp />

      {/* Success Dialog */}
      <Dialog
        open={openModal}
        onClose={onCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">
            Correo Enviado Correctamente
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1 w-[400px]">
              Se ha envíado a tu correo una liga para que puedas cambiar tu
              contraseña
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal} color="success" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
