import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

// Get categories
export async function getCategories() {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return categories
    return {
      success: true,
      categories: res?.data?.categories || [],
    };
  } catch (error) {
    console.log("GET CATEGORIES ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo las categorías"),
    };
  }
}

// Add category
export async function addCategory(body: {
  name: string | null | undefined;
  securityID: string | null | undefined;
}) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/new`;
    const res = await axios.post(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return category id
    return {
      success: true,
      categoryId: res?.data?.id,
    };
  } catch (error) {
    console.log("ADD CATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error añadiendo la categoría"),
    };
  }
}

// Edit category
export async function editCategory(
  id: string | null | undefined,
  body: {
    name: string | null | undefined;
    securityID: string | null | undefined;
  }
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/${id}`;
    const res = await axios.put(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    return { success: true };
  } catch (error) {
    console.log("EDIT CATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error editando la categoría"),
    };
  }
}

// Delete category
export async function deleteCategory(
  id: string | null | undefined,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/${id}`;
    const res = await axios.delete(url, { data: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    return { success: true };
  } catch (error) {
    console.log("DELETE CATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error eliminando la categoría"),
    };
  }
}

// Get subcategories
export async function getSubcategories() {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/subcategories`;
    const res = await axios.get(url);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return categories
    return {
      success: true,
      subcategories: res?.data?.subcategories || [],
    };
  } catch (error) {
    console.log("GET SUBCATEGORIES ERROR", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error obteniendo las subcategorías"
      ),
    };
  }
}

// Add subcategory
export async function addSubcategory(body: {
  name: string | null | undefined;
  securityID: string | null | undefined;
}) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/subcategories/new`;
    const res = await axios.post(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    // Return subcategory id
    return {
      success: true,
      subcategoryId: res?.data?.id,
    };
  } catch (error) {
    console.log("ADD SUBCATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error añadiendo la subcategoría"),
    };
  }
}

// Edit subcategory
export async function editSubcategory(
  id: string | null | undefined,
  body: {
    name: string | null | undefined;
    securityID: string | null | undefined;
  }
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/subcategories/${id}`;
    const res = await axios.put(url, body);

    // Check response
    if (!checkResponse(res)) throw res;

    return { success: true };
  } catch (error) {
    console.log("EDIT SUBCATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error editando la subcategoría"),
    };
  }
}

// Delete subcategory
export async function deleteSubcategory(
  id: string | null | undefined,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/categories/subcategories/${id}`;
    const res = await axios.delete(url, { data: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    return { success: true };
  } catch (error) {
    console.log("DELETE SUBCATEGORY ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error eliminando la subcategoría"),
    };
  }
}
