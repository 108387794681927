import { IconButton } from "@mui/material";
import { logout } from "actions/AuthActions";
import ToasterComp from "components/General/Toaster";
import { useAuthContext } from "contexts/AuthContext/context";
import { ButtonEl } from "styledComponents/button";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";

// Styles
const styles = {
  container:
    "w-full h-[70px] flex justify-between items-center border-b-[1px] border-b-[#eee] border-solid shadow-bs1 px-[20px]",
  title: "mt-[5px] ml-[10px] sm:text-[22px] text-[18px] font-[600]",
  rightSide: "flex justify-center items-center",
  userImg:
    "w-[40px] h-[40px] rounded-[50%] mr-[20px] object-cover sm:block hidden",
};

// Interface
interface TopbarInterface {
  title: string;
  smallView: boolean;
  setShowNavbar: React.Dispatch<React.SetStateAction<boolean>>;
}

// Component
export default function Topbar({
  title,
  smallView,
  setShowNavbar,
}: TopbarInterface) {
  // Variables
  const { authState, authDispatch } = useAuthContext();
  const { partner } = authState;

  return (
    <div className={styles.container}>
      <div className="flex items-center">
        {smallView && (
          <IconButton onClick={() => setShowNavbar(true)}>
            <MenuIcon />
          </IconButton>
        )}
        <h1 className={styles.title}>{title}</h1>
      </div>
      <div className={styles.rightSide}>
        <img
          src={partner?.user_img ? partner.user_img : "/imgs/no_user.jpg"}
          alt="Imagen de Usuario"
          className={styles.userImg}
        />

        {!smallView && (
          <ButtonEl
            onClick={() => logout(authDispatch)}
            className="outlined-gd"
          >
            Logout
          </ButtonEl>
        )}
      </div>

      {/* Toaster */}
      <ToasterComp />
    </div>
  );
}
