export interface OrderType {
  id?: string | undefined | null;
  order_id?: string | undefined | null;
  products?: Array<any> | undefined | null;
  user_id?: string | undefined | null;
  total_credits?: number | undefined | null;
  date_created?: any | undefined | null;
  end_of_cycle?: any | undefined | null | Date;
  delivery_id?: string | undefined | null;
  tracking_url?: string | undefined | null;
  status?: string;
  is_in_support?: boolean | undefined | null;
  worker_id?: string | undefined | null;
  worker_name?: string | undefined | null;
  address?: any | undefined | null;
  support_data?: any | undefined | null;
  product_imgs_bucket_paths?: Array<any> | undefined | null;
}

export const orderStatusKeys: { [key: string]: string } = {
  assigning_worker: "assigning_worker",
  preparing_order: "preparing_order",
  order_shipped: "order_shipped",
  order_with_client: "order_with_client",
  preparing_devolution: "preparing_devolution",
  returning_order: "returning_order",
  order_in_store: "order_in_store",
  order_canceled: "order_canceled",
};

export const orderStatusNext: { [key: string]: any } = {
  assigning_worker: "preparing_order",
  preparing_order: "order_shipped",
  order_shipped: "order_with_client",
  order_with_client: "preparing_devolution",
  preparing_devolution: "returning_order",
  returning_order: "order_in_store",
};

// Stepper flows
export const orderFlow: { [key: string]: number } = {
  assigning_worker: 0,
  preparing_order: 1,
  order_shipped: 2,
  order_with_client: 3,
  preparing_devolution: 4,
  returning_order: 5,
  order_in_store: 6,
};

export const supportStatus: { [key: string]: string } = {
  contacting_customer: "contacting_customer",
  customer_in_process: "customer_in_process",
  support_ended: "support_ended",
};

export const supportStatusNext: { [key: string]: string } = {
  contacting_customer: "customer_in_process",
  customer_in_process: "support_ended",
};
