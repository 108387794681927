import React from "react";
import "./App.css";
import { useAuthContext } from "contexts/AuthContext/context";
import { checkLoggedPartner } from "actions/AuthActions";
import { getModels } from "actions/ModelsActions";
import { getCategories, getSubcategories } from "actions/CategoryActions";
import { useAppContext } from "contexts/AppContext/context";
import toast from "react-hot-toast";
import Router from "./Router";

function App() {
  const [partnerChecked, setPartnerChecked] = React.useState(false);
  const { authDispatch, authState } = useAuthContext();
  const { partner } = authState;
  const { appDispatch } = useAppContext();

  // Get the initial data that the app needs to start working
  async function getAppData() {
    // Get initial models
    const modelsRes = await getModels(); // Call action
    if (!modelsRes.success) {
      toast.error(modelsRes.msg); // Show error
      appDispatch({ type: "setInitialModels", payload: [] }); // Set models []
    }
    appDispatch({ type: "setInitialModels", payload: modelsRes.models }); // Set with founded models

    // Get categories
    const categoriesRes = await getCategories(); // Call action
    if (!categoriesRes.success) {
      toast.error(categoriesRes.msg); // Show error
      appDispatch({ type: "setCategories", payload: [] }); // Set categories []
    }
    appDispatch({
      type: "setCategories",
      payload: categoriesRes.categories, // Set with founded categories
    });

    // Get subcategories
    const subcategoriesRes = await getSubcategories(); // Call action
    if (!subcategoriesRes.success) {
      toast.error(subcategoriesRes.msg); // Show error
      appDispatch({ type: "setSubcategories", payload: [] }); // Set subcategories []
    }
    appDispatch({
      type: "setSubcategories",
      payload: subcategoriesRes.subcategories, // Set with founded categories
    });
  }

  // Only on render, check for a logged partner
  React.useEffect(() => {
    checkLoggedPartner(authDispatch);

    // Wait till the changes are made in the context
    setTimeout(() => {
      setPartnerChecked(true);
    }, 200);
  }, []);

  // Everytime partner changes, if there is a partner logged, get app data
  React.useEffect(() => {
    if (partner) {
      getAppData();
    }
  }, [partner]);

  // If it's ready to render return app, if not return loader
  return partnerChecked ? (
    <div className="App">
      <Router />
    </div>
  ) : (
    // If it's not ready return loader
    <p>Loading...</p>
  );
}

export default App;
