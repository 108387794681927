import { orderStatusKeys, supportStatus } from "types/Order";

// This function returns the data and labels of the order status depending on the status key
export function orderStatus(status: string | undefined | null) {
  switch (status) {
    case orderStatusKeys.assigning_worker:
      return {
        lables: {
          long: "Asignar Pedido a Trabajador",
          short: "Asignar Trabajador",
        },
        chip: {
          label: "Asignar Trabajador",
          color: "warning",
        },
        actionLabel: "Asignar pedido a trabajador",
        confirm: {
          title: "Asignarme el Pedido",
          text: "¿Seguro que quieres asignarte este pedido?",
        },
        nextBtnLabel: "Asignarme el Pedido",
        stepperLabel: "Asignar",
      };
    case orderStatusKeys.preparing_order:
      return {
        lables: {
          long: "Preparando Pedido para Envío",
          short: "Preparando Envío",
        },
        chip: {
          label: "Preparando Envío",
          color: "secondary",
        },
        actionLabel: "Preparando el pedido para enviar",
        confirm: {
          title: "Confirmar Pedido Enviado",
          text: "¿Seguro que el pedido se ha enviado?",
        },
        nextBtnLabel: "Pedido Enviado",
        stepperLabel: "Envío",
      };
    case orderStatusKeys.order_shipped:
      return {
        lables: {
          long: "Pedido En Camino",
          short: "En Camino",
        },
        chip: {
          label: "En Camino",
          color: "info",
        },
        actionLabel: "El pedido está en camino",
        confirm: {
          title: "El Pedido Se Ha Recibido",
          text: "¿Seguro que el pedido se ha recibido por el cliente?",
        },
        nextBtnLabel: "Pedido Recibido",
        stepperLabel: "En Camino",
      };
    case orderStatusKeys.order_with_client:
      return {
        lables: {
          long: "Pedido Recibido",
          short: "Recibido",
        },
        chip: {
          label: "Recibido",
          color: "success",
        },
        confirm: {
          title: "Preparar Devolución",
          text: "¿Seguro que quiere preparar la devolución para este pedido?",
        },
        nextBtnLabel: "Preparar Devolución",
        actionLabel: "El pedido se ha recibido",
        stepperLabel: "Recibido",
      };
    case orderStatusKeys.preparing_devolution:
      return {
        lables: {
          long: "Preparando Pedido para Devolución",
          short: "Preparando Devolución",
        },
        chip: {
          label: "Preparando Devolución",
          color: "secondary",
        },
        actionLabel: "Preparando la devolución",
        confirm: {
          title: "Confirmar Información de Devolución",
          text: "¿Seguro que se ha registrado correctamente la información de la devolución?",
        },
        nextBtnLabel: "Devolución Registrada",
        stepperLabel: "Devolución",
      };
    case orderStatusKeys.returning_order:
      return {
        lables: {
          long: "Devolviendo Pedido",
          short: "Preparando Devolución",
        },
        chip: {
          label: "Devolviendo Pedido",
          color: "info",
        },
        confirm: {
          title: "Pedido en Almacén",
          text: "¿Seguro que el pedido ya está en el almacén?",
        },
        nextBtnLabel: "Pedido en Almacén",
        actionLabel:
          "El cliente ha devuelto sus prendas y están en camino al almacén",
        stepperLabel: "En camino",
      };
    case orderStatusKeys.order_in_store:
      return {
        lables: {
          long: "Pedido en Almacén",
          short: "Pedido en Almacén",
        },
        chip: {
          label: "En Almacén",
          color: "success",
        },
        actionLabel: "El pedido está en el almacén",
        stepperLabel: "En Almacén",
      };
    case orderStatusKeys.order_canceled:
      return {
        lables: {
          long: "Pedido Cancelado",
          short: "Pedido Cancelado",
        },
        chip: {
          label: "Cancelado",
          color: "error",
        },
        actionLabel: "El pedido ha sido cancelado",
        stepperLabel: "Cancelado",
      };
    default:
      return {
        lables: {
          long: "Sin Estado",
          short: "Sin Estado",
        },
        chip: {
          label: "Sin Estado",
          color: "error",
        },
        stepperLabel: "Sin Estado",
      };
  }
}

export function orderSupportStatus(status: string | undefined | null) {
  switch (status) {
    case supportStatus.contacting_customer:
      return {
        lables: {
          long: "Contactando Cliente",
          short: "Contactando",
        },
        actionLabel: "El cliente debe ser contactado",
        confirm: {
          title: "Cliente contactado",
          text: "¿Seguro que el cliente se ha contactado correctamente?",
        },
        nextBtnLabel: "Cliente Contactado",
        stepperLabel: "Contactando",
      };
    case supportStatus.customer_in_process:
      return {
        lables: {
          long: "Soporte en Seguimiento",
          short: "En Seguimiento",
        },
        actionLabel: "El soporte está en seguimiento",
        confirm: {
          title: "Confirmar Soporte Terminado",
          text: "¿Seguro que el soporte ha terminado?",
        },
        nextBtnLabel: "Terminar Soporte",
        stepperLabel: "En Seguimiento",
      };

    case supportStatus.support_ended:
      return {
        lables: {
          long: "Soporte Terminado",
          short: "Terminado",
        },
        actionLabel: "Soporte Terminado",
        stepperLabel: "Terminado",
      };
    default:
      return {
        lables: {
          long: "Sin Estado",
          short: "Sin Estado",
        },
        chip: {
          label: "Sin Estado",
          color: "error",
        },
        stepperLabel: "Sin Estado",
      };
  }
}
