import React from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { checkResponse } from "helpers/requests";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import SortIcon from "@mui/icons-material/Sort";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { useAuthContext } from "contexts/AuthContext/context";

// Styles
const styles = {
  dataContainer:
    "mt-[30px] w-full grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-[20px] mb-[40px]",
  dataItem:
    "w-full flex flex-col items-start rounded-[10px] py-[15px] px-[20px] shadow-bs4",
  iconContainer:
    "bg-[#eeffe0] flex items-center justify-center p-[7px] mb-[15px] rounded-[10px]",
  icon: "text-[30px] text-gd",
  info: "text-start",
  label: "text-[15px] font-medium leading-[15px] mb-[10px]",
  value: "text-[25px] font-semibold leading-[25px] text-[#21793e]",
};

export default function TopData() {
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [totalModels] = React.useState(12);
  const [totalCategories, setTotalCategories] = React.useState(0);
  const [totalSubcategories, setTotalSubcategories] = React.useState(0);

  // Get totals
  async function getTotals() {
    try {
      // Get totals
      const server = process.env.REACT_APP_SERVER_URL;
      const url = `${server}/graphs/totals`;
      const res = await axios.get(url, { params: { securityID: partner?.id } });

      // Check responses
      if (!checkResponse(res)) throw res;

      setTotalUsers(res.data?.totals?.total_users);
      setTotalCategories(res.data?.totals?.total_categories);
      setTotalSubcategories(res.data?.totals?.total_subcategories);
    } catch (error) {
      console.log("GET TOTALS ERROR:", error);
      toast.error("Hubo un error obteniendo los totales generales");
    }
  }

  // On render
  React.useEffect(() => {
    getTotals();
  }, []);

  return (
    <div className={styles.dataContainer}>
      <div className={styles.dataItem}>
        <div className={styles.iconContainer}>
          <PeopleAltIcon className={styles.icon} />
        </div>

        <div className={styles.info}>
          <p className={styles.label}>Total de Usuarios</p>
          <p className={styles.value}>{totalUsers}</p>
        </div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.iconContainer}>
          <CheckroomIcon className={styles.icon} />
        </div>

        <div className={styles.info}>
          <p className={styles.label}>Total de Modelos</p>
          <p className={styles.value}>{totalModels}</p>
        </div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.iconContainer}>
          <FormatListBulletedIcon className={styles.icon} />
        </div>

        <div className={styles.info}>
          <p className={styles.label}>Total de Categorías</p>
          <p className={styles.value}>{totalCategories}</p>
        </div>
      </div>

      <div className={styles.dataItem}>
        <div className={styles.iconContainer}>
          <SortIcon className={styles.icon} />
        </div>

        <div className={styles.info}>
          <p className={styles.label}>Subcategorías</p>
          <p className={styles.value}>{totalSubcategories}</p>
        </div>
      </div>
    </div>
  );
}
