import { useAppContext } from "contexts/AppContext/context";
import { booleanTranslate } from "helpers/translators";
import React from "react";
import { InputEl } from "styledComponents/input";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  addSubscription,
  deleteSubscription,
  editSubscription,
} from "actions/SubscriptionsActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SubscriptionType } from "types/Subscription";
import { validateInputs } from "helpers/requests";
import { useAuthContext } from "contexts/AuthContext/context";

interface SubscriptionFormInterface {
  mode: string;
}

export default function SubscriptionForm({ mode }: SubscriptionFormInterface) {
  const { appState, appDispatch } = useAppContext();
  const { authState } = useAuthContext();
  const { partner } = authState;
  const { subscriptionOnView: subscription } = appState;
  const [editMode] = React.useState(mode === "edit");
  const [name, setName] = React.useState(editMode ? subscription?.name : "");
  const [description, setDescription] = React.useState(
    editMode ? subscription?.description : ""
  );
  const [monthlyFee, setMonthlyFee] = React.useState<number | undefined | null>(
    editMode ? subscription?.monthly_fee : null
  );
  const [creditAmmount, setCreditAmmount] = React.useState<
    number | undefined | null
  >(editMode ? subscription?.credit_ammount : null);
  const [showToUsers, setShowToUsers] = React.useState(
    editMode ? subscription?.show_to_users : true
  );
  const [stripePriceId, setStripePriceId] = React.useState(
    editMode ? subscription?.stripe_price_id : ""
  );
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
    React.useState(false);
  const navigate = useNavigate();

  async function editSubscriptionHandler() {
    // Update suscription
    const body = {
      credit_ammount: creditAmmount,
      description,
      monthly_fee: monthlyFee,
      name,
      show_to_users: showToUsers,
      stripe_price_id: stripePriceId,
    };
    const res = await editSubscription(subscription?.id, body, partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Update context
    // @ts-ignore
    const updatedData: SubscriptionType = { ...subscription, ...body };
    appDispatch({
      type: "setSubscriptionOnView",
      payload: updatedData,
    });
    toast.success("Suscripción actualizada");
    navigate("/private/subscriptions/view");
  }

  async function addSubscriptionHandler() {
    // Validate inputs
    const inputs = [
      creditAmmount,
      description,
      monthlyFee,
      name,
      showToUsers,
      stripePriceId,
    ];
    if (!validateInputs(inputs)) {
      toast.error("Debes llenar todos los campos");
      return;
    }

    // Add subscription
    const body = {
      credit_ammount: creditAmmount,
      description,
      monthly_fee: monthlyFee,
      name,
      show_to_users: showToUsers,
      stripe_price_id: stripePriceId,
    };
    const res = await addSubscription(body, partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Suscripción añadida");
    navigate("/private/subscriptions");
  }

  async function deleteSubscriptionHandler() {
    const res = await deleteSubscription(subscription?.id, partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }
    toast.success("Suscripción eliminada");
    navigate("/private/subscriptions");
  }

  return (
    <div className="w-[450px] shadow-bs3 py-[35px] px-[30px] mt-[20px] rounded-[15px] relative">
      <InputEl>
        <p>Nombre</p>
        <div>
          <input
            placeholder="Escríba aquí"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </InputEl>
      <InputEl>
        <p>Descripción</p>
        <div>
          <textarea
            onChange={(e) => setDescription(e.target.value)}
            rows={2}
            value={description || ""}
            placeholder="Escríba aquí"
          />
        </div>
      </InputEl>
      <InputEl>
        <p>Costo Mensual</p>
        <div>
          <input
            type="number"
            placeholder="Escríba aquí"
            value={monthlyFee || ""}
            onChange={(e) => setMonthlyFee(parseInt(e.target.value, 10))}
          />
        </div>
      </InputEl>
      <InputEl>
        <p>Créditos</p>
        <div>
          <input
            type="number"
            placeholder="Escríba aquí"
            value={creditAmmount || ""}
            onChange={(e) => setCreditAmmount(parseInt(e.target.value, 10))}
          />
        </div>
      </InputEl>
      <InputEl>
        <p>Visible para Usuarios</p>
        <div>
          <select
            value={booleanTranslate(showToUsers)}
            onChange={(e) => {
              if (e.target.value === "Si") setShowToUsers(true);
              if (e.target.value === "No") setShowToUsers(false);
            }}
          >
            <option value="Si">Si</option>
            <option value="No">No</option>
          </select>
        </div>
      </InputEl>
      <InputEl>
        <p>Id Stripe</p>
        <div>
          <input
            placeholder="Escríba aquí"
            value={stripePriceId || ""}
            onChange={(e) => setStripePriceId(e.target.value)}
          />
        </div>
      </InputEl>

      <Button
        onClick={editMode ? editSubscriptionHandler : addSubscriptionHandler}
        variant="contained"
        size="large"
        fullWidth
        sx={{ mb: "10px", mt: "10px" }}
      >
        {editMode ? "Guardar Cambios" : "Añadir Suscripción"}
      </Button>

      {editMode && (
        <Button
          onClick={() => setOpenConfirmDeleteModal(true)}
          variant="outlined"
          color="error"
          fullWidth
          sx={{ mb: "10px", mt: "10px" }}
        >
          Eliminar Suscripción
        </Button>
      )}

      {/* Delete subscription model */}
      <Dialog
        open={openConfirmDeleteModal}
        onClose={() => setOpenConfirmDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">Eliminar Suscripción</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1">
              ¿Estás seguro que quieres eliminar la suscripción?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteSubscriptionHandler} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
