import { Chip, IconButton } from "@mui/material";
import { orderStatus } from "helpers/orderStatus";
import { table } from "styleConstants";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { useNavigate } from "react-router-dom";
import { OrderType } from "types/Order";
import PersonIcon from "@mui/icons-material/Person";
import { useAppContext } from "contexts/AppContext/context";
import { cutDisplayText } from "helpers/string";
import { daysDifference } from "helpers/dateFormater";

interface OrdersTableInterface {
  orders: Array<OrderType>;
  loading: boolean;
}

export default function OrdersTable({ orders, loading }: OrdersTableInterface) {
  // Variables
  const { appDispatch } = useAppContext();
  const navigate = useNavigate();

  if (loading)
    return (
      <div className="w-full flex justify-center">
        <img src="/imgs/loading.gif" alt="" className="w-[100px] mt-[100px]" />
      </div>
    );

  if (orders?.length === 0)
    return (
      <p className="font-[500] text-gr3 mt-[100px] text-[14px]">
        No hay pedidos que mostrar
      </p>
    );
  return (
    <table className={table.container}>
      <thead>
        {/* Headers */}
        <tr className={`${table.row} h-[50px]`}>
          <th className={`${table.cell} ${table.th}`}>Asignado</th>
          <th className={`${table.cell} ${table.th}`}>Creación</th>
          <th className={`${table.cell} ${table.th}`}>Fin de Ciclo</th>
          <th className={`${table.cell} ${table.th}`}>Estado</th>
          <th className={`${table.cell} ${table.th}`}>Créditos</th>
          <th className={`${table.cell} ${table.th}`}>Detalles</th>
        </tr>
      </thead>
      <tbody>
        {/* Items */}
        {orders?.map((order: any) => {
          let diffDays: number | string = 0;
          let diffDayColor: string = table.fields.date;

          if (order?.end_of_cycle) {
            diffDays = daysDifference(
              new Date(),
              new Date(order.end_of_cycle._seconds * 1000)
            );
            if (diffDays < 5) diffDayColor = table.fields.error;
            else diffDayColor = table.fields.success;
          } else diffDays = "-";

          return (
            <tr className={table.row} key={order.id}>
              <td
                className={`${table.cell} ${table.td} ${table.fields.asigned}`}
              >
                <PersonIcon className="mr-[10px] text-gl" />
                <p className="mt-[2px]">
                  {order?.worker_name
                    ? cutDisplayText(order.worker_name, 15)
                    : "Sin Trabajador"}
                </p>
              </td>
              <td className={`${table.cell} ${table.td} ${table.fields.date}`}>
                {new Date(
                  order.date_created._seconds * 1000
                ).toLocaleString() || "Sin fecha"}
              </td>
              <td className={`${table.cell} ${table.td} ${diffDayColor}`}>
                {diffDays} días
              </td>
              <td className={`${table.cell} ${table.td}`}>
                {order.is_in_support ? (
                  <Chip
                    label="En Soporte"
                    variant="outlined"
                    size="small"
                    // @ts-ignore
                    color="error"
                  />
                ) : (
                  <Chip
                    label={orderStatus(order.status)?.chip.label}
                    variant="outlined"
                    size="small"
                    // @ts-ignore
                    color={orderStatus(order.status)?.chip.color}
                  />
                )}
              </td>

              <td className={`${table.cell} ${table.td}`}>
                {order.total_credits.toFixed(2) || "Sin créditos"}
              </td>
              <td className={`${table.cell} ${table.td}`}>
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate(`/private/orders/view/${order.id}`);
                    appDispatch({ type: "setOrderOnView", payload: order });
                  }}
                >
                  <RemoveRedEyeOutlinedIcon className={table.icon} />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
