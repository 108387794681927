import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import AuthProvider from "contexts/AuthContext/context";
import AppProvider from "contexts/AppContext/context";
import App from "./App";
import { theme } from "./theme";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <AppProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
