import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import { divs, texts } from "styleConstants";
import BackButton from "components/General/BackButton";
import capitalize from "helpers/capitalize";
import { useAppContext } from "contexts/AppContext/context";
import React from "react";
import { CategoryType, SubcategoryType } from "types/Model";
import CloseIcon from "@mui/icons-material/Close";
import { validateInputs } from "helpers/requests";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { editModel } from "actions/ModelsActions";
import { getSubcategoryData } from "helpers/categories";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAuthContext } from "contexts/AuthContext/context";

// Styles
const styles = {
  subcategoriesList: {
    list: "w-full flex flex-col mt-[-15px] mb-[30px]",
    item: "flex justify-between mb-[5px]",
    text: "text-[15px] text-gr1",
    deleteBtn: "text-rd",
  },
  sizes: {
    container: "w-full",
    row: "w-full flex justify-between mb-[10px] items-end",
    sizeText: "text-[15px] cursor-pointer",
    qtyInput:
      "outline-none border-b-[1px] border-b-solid border-b-gr1 w-full px-[5px] mr-[20px] placeholder:text-[14px]",
    inputsContainer: "flex",
    qtyDiv: "flex w-[65px] justify-start justify-between",
    qtyText: "",
  },
};

// Component
export default function EditModel() {
  // Variables
  const { appState, appDispatch } = useAppContext();
  const {
    categories,
    modelOnView: model,
    initialModels,
    subcategories: allSubcategories,
  } = appState;
  const { authState } = useAuthContext();
  const { partner } = authState;
  const navigate = useNavigate();

  // Input values
  const [name, setName] = React.useState(model?.name || "");
  const [brand, setBrand] = React.useState(model?.brand || "");
  const [description, setDescription] = React.useState(
    model?.description || ""
  );
  const [price, setPrice] = React.useState(model?.price?.toString() || "");
  const [euros, setEuros] = React.useState(model?.euros?.toString() || "");
  const [category, setCatgory] = React.useState<CategoryType | undefined>(
    // Search in categories for the category object that matches the model category id
    categories?.find((c) => c.id === model?.category_id)
  );
  const [subcategories, setSubcategories] = React.useState<
    Array<SubcategoryType | undefined> | null | undefined
  >(
    model?.subcategories?.map((subcategoryId) =>
      getSubcategoryData(subcategoryId, allSubcategories)
    ) || []
  );
  const [sizes, setSizes] = React.useState<{
    [key: string]: number;
  }>(model?.sizes || {});
  const [newSize, setNewSize] = React.useState<string>("");
  const [newBrandSize, setNewBrandSize] = React.useState<string>("");
  const [brandSizes, setBrandSizes] = React.useState<Set<string>>(
    model?.sizes_in_brand_store
      ? new Set(model?.sizes_in_brand_store)
      : new Set()
  );

  // Edit Model Handler
  async function editModelHandler() {
    // Validate fields
    if (
      !validateInputs([
        name,
        brand,
        description,
        price,
        euros,
        category,
        subcategories,
      ])
    ) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    if (subcategories && subcategories?.length <= 0) {
      toast.error("Debes seleccionar por lo menos una subcategoría");
      return;
    }

    // Create subcategories id array
    const subgategoryIds: Array<string> = [];
    subcategories?.forEach((subcategory) => {
      if (subcategory?.id) subgategoryIds.push(subcategory?.id);
    });

    // Create request body
    const editedModel = {
      name,
      brand,
      description,
      price: parseFloat(price),
      euros: parseFloat(euros),
      category_id: category?.id,
      subcategories: subgategoryIds,
      sizes,
      sizes_in_brand_store: Array.from(brandSizes),
    };

    // Make request
    const res = await editModel(model?.model_id, editedModel, partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Change context
    const newData = { ...model, ...editedModel, sizes };
    appDispatch({ type: "setModelOnView", payload: newData }); // Set modelOnView
    if (initialModels) {
      // Delete model from context
      const fiteredModels = initialModels?.filter(
        (m) => m.model_id !== model?.model_id
      );
      // Add new model data again
      appDispatch({
        type: "setInitialModels",
        payload: [...fiteredModels, newData],
      });
    }
    navigate("/private/models/view"); // Go to the view of that model
    toast.success("Modelo editado correctamente");
  }

  function changeSizeQuantity(size: string, newQty: string) {
    const newSizes: any = {};
    Object.keys(sizes).forEach((s) => {
      if (s === size) newSizes[s] = parseInt(newQty, 10);
      else newSizes[s] = sizes[s];
    });
    setSizes(newSizes);
  }

  function addSize() {
    if (!validateInputs([newSize])) return;
    const newSizes = sizes;
    newSizes[newSize] = 0;
    setSizes({ ...newSizes });
  }

  function removeSize(size: string) {
    const newSizes: any = {};
    Object.keys(sizes).forEach((s) => {
      if (s !== size) newSizes[s] = sizes[s];
    });
    setSizes(newSizes);
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models/view" />
        <h1 className={texts.pageTitle.h1}>Editar Modelo</h1>
      </div>

      {/* Inputs Container */}
      <div className={`${divs.inputsContainer} sm:w-[400px] w-full`}>
        <InputEl>
          <p>Nombre</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Marca</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Descripción</p>
          <div>
            <input
              placeholder="Escríba aquí"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Créditos</p>
          <div>
            <input
              placeholder="Escríba aquí"
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Euros</p>
          <div>
            <input
              placeholder="Escríba aquí"
              type="number"
              value={euros}
              onChange={(e) => setEuros(e.target.value)}
            />
          </div>
        </InputEl>
        <InputEl>
          <p>Categoría</p>
          <div>
            <select
              value={category?.id}
              onChange={(e) => {
                // If category is no value option
                if (e.target.value === "") {
                  setCatgory(undefined); // Clean variable
                  return;
                }
                setCatgory(categories?.find((c) => c.id === e.target.value)); // Set category object
              }}
            >
              <option value="">Categoría</option>
              {categories?.map((c) => (
                <option value={c?.id} key={c?.id}>
                  {capitalize(c?.name)}
                </option>
              ))}
            </select>
          </div>
        </InputEl>
        <InputEl>
          <p>Añadir Subcategoría</p>
          <div>
            <select
              defaultValue=""
              onChange={(e) => {
                // Try to find in subcategory is already in list
                const isAlready = !!subcategories?.find(
                  (sub) => sub?.id === e.target.value
                );

                // Validate
                if (subcategories && e.target.value !== "" && !isAlready) {
                  setSubcategories([
                    ...subcategories,
                    getSubcategoryData(e.target.value, allSubcategories),
                  ]);
                }
              }}
            >
              <option value="">Subcategoría</option>
              {allSubcategories?.map((sub) => (
                <option value={sub.id} key={sub.id}>
                  {capitalize(sub.name)}
                </option>
              ))}
            </select>
          </div>
        </InputEl>

        {/* List of subcategories */}
        {subcategories && subcategories.length > 0 && (
          <ul className={styles.subcategoriesList.list}>
            {subcategories?.map((sub) => (
              <li key={sub?.id} className={styles.subcategoriesList.item}>
                <p className={styles.subcategoriesList.text}>
                  {capitalize(sub?.name || "Sin información")}
                </p>
                <button
                  type="button"
                  onClick={() => {
                    const filteredSubcategories = subcategories.filter(
                      (subc) => subc?.id !== sub?.id
                    );
                    setSubcategories(filteredSubcategories);
                  }}
                >
                  <CloseIcon
                    className={styles.subcategoriesList.deleteBtn}
                    style={{ fontSize: "13px" }}
                  />
                </button>
              </li>
            ))}
          </ul>
        )}

        {/* Sizes in Stock */}
        <div className="w-full flex flex-col">
          <div className="flex items-end">
            <InputEl>
              <p>Tallas en Inventario</p>
              <div>
                <input
                  placeholder="Nueva Talla"
                  value={newSize}
                  onChange={(e) => setNewSize(e.target.value)}
                />
              </div>
            </InputEl>
            <IconButton sx={{ ml: 2, mb: 4.2 }} onClick={addSize}>
              <AddIcon />
            </IconButton>
          </div>

          <div className="flex flex-col">
            {Object.keys(sizes).map((size: string) => (
              <div
                className="flex justify-between items-center mb-1"
                key={size}
              >
                <p className={styles.subcategoriesList.text}>{size}</p>
                <div>
                  <input
                    type="number"
                    className="border-b-[1px] border-gd w-[70px] outline-none pl-2"
                    value={sizes[size]}
                    onChange={(e) => changeSizeQuantity(size, e.target.value)}
                  />
                  <CloseIcon
                    className="text-red-400"
                    sx={{ fontSize: "13px", ml: 3, cursor: "pointer" }}
                    onClick={() => removeSize(size)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Sizes in Brand */}
        <div className="w-full flex flex-col mt-6">
          <div className="flex items-end">
            <InputEl>
              <p>Tallas de la Marca</p>
              <div>
                <input
                  placeholder="Nueva Talla"
                  value={newBrandSize}
                  onChange={(e) => setNewBrandSize(e.target.value)}
                />
              </div>
            </InputEl>
            <IconButton
              sx={{ ml: 2, mb: 4.2 }}
              onClick={() => {
                if (!validateInputs([newBrandSize])) return;
                const temp = new Set(brandSizes);
                temp.add(newBrandSize);
                setBrandSizes(temp);
                setNewBrandSize("");
              }}
            >
              <AddIcon />
            </IconButton>
          </div>

          <div className="flex flex-col">
            {Array.from(brandSizes).map((size: string) => (
              <div
                className="flex justify-between items-center mb-1"
                key={size}
              >
                <p className={styles.subcategoriesList.text}>{size}</p>
                <CloseIcon
                  className="text-red-400"
                  sx={{ fontSize: "13px", ml: 3, cursor: "pointer" }}
                  onClick={() => {
                    const temp = new Set(brandSizes);
                    temp.delete(size);
                    setBrandSizes(temp);
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Button */}
        <ButtonEl
          type="button"
          className="lg w-full mt-[70px]"
          onClick={editModelHandler}
        >
          Guardar Cambios
        </ButtonEl>
      </div>
    </div>
  );
}
