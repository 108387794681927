import { storage } from "firebaseConfig";
import { ref, uploadBytes } from "firebase/storage";

// Function to upload a single image to firebase storage
export async function uploadSingleImage(file: File) {
  try {
    if (!file) {
      return {
        status: false,
        msg: "No se ha seleccionado ningún archivo",
      };
    }

    // Create a unique id using time of upload
    const fileName = `${new Date().getTime()}${file.name}`;

    // Create a reference to 'images/mountains.jpg'
    const fileRef = ref(storage, `frontend-files/${fileName}`);

    // Upload file
    const res = await uploadBytes(fileRef, file);

    return {
      status: true,
      fileRef: res.ref,
    };
  } catch (error) {
    console.log(error);
    return { status: false, msg: "Hubo un error subiendo la imágen." };
  }
}

// Function to upload a multiple images to firebase storage
export async function uploadMultipleImages(files: File[]) {
  try {
    if (files.length <= 0) {
      return {
        status: false,
        msg: "No se han seleccionado archivos",
      };
    }

    // Create upload primises
    const promises: any = [];
    files.forEach((file: File) => {
      // Create a unique id using time of upload
      const fileName = `${new Date().getTime()}${file.name}`;

      // Create a reference to 'images/mountains.jpg'
      const fileRef = ref(storage, `frontend-files/${fileName}`);

      // Upload file promise
      const primise = uploadBytes(fileRef, file);
      promises.push(primise);
    });

    const res = await Promise.all(promises);

    return {
      status: true,
      fileRefs: res.map((r: any) => r.ref),
    };
  } catch (error) {
    console.log(error);
    return { status: false, msg: "Hubo un error subiendo las imágenes" };
  }
}
