import BackButton from "components/General/BackButton";
import { useAppContext } from "contexts/AppContext/context";
import React from "react";
import { Link } from "react-router-dom";
import { divs, texts } from "styleConstants";
import { ButtonEl } from "styledComponents/button";
import { ImageType } from "types/Model";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

export default function ViewImages() {
  const { appState } = useAppContext();
  const { modelOnView } = appState;
  const [imgs] = React.useState<Array<ImageType> | null | undefined>(
    modelOnView?.model_imgs
  );

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models/view" />
        <h1 className={texts.pageTitle.h1}>Imágenes del Modelo</h1>
      </div>

      {/* Actions */}
      <div className="md:w-[78%] w-full flex md:justify-end justify-center">
        <Link to="/private/models/images/add">
          <ButtonEl className="mx-[5px] sm outlined-gd">Añadir</ButtonEl>
        </Link>
        <Link to="/private/models/images/order">
          <ButtonEl className="mx-[5px] sm outlined-gr">Ordenar</ButtonEl>
        </Link>
        <Link to="/private/models/images/delete">
          <ButtonEl className="mx-[5px] sm outlined-rd">Eliminar</ButtonEl>
        </Link>
      </div>

      {/* Images Contnainer */}
      <div className={divs.modelImagesPage.container}>
        <Gallery>
          <div className={divs.modelImagesPage.grid}>
            {imgs?.map((img) => (
              <div className={divs.modelImagesPage.item} key={img.bucketPath}>
                <Item
                  original={img?.url || "/imgs/no_img.png"}
                  thumbnail={img?.url || "/imgs/no_img.png"}
                  height="700"
                  width="600"
                  alt="Imágen del Modelo"
                >
                  {({ ref, open }) => (
                    <img
                      style={{ cursor: "pointer" }}
                      src={img?.url || "/imgs/no_img.png"}
                      ref={ref as React.MutableRefObject<HTMLImageElement>}
                      onClick={open}
                      alt="Imágen del Modelo"
                      className={divs.modelImagesPage.img}
                    />
                  )}
                </Item>
              </div>
            ))}
          </div>
        </Gallery>
      </div>
    </div>
  );
}
