import React from "react";
import { Chart } from "react-chartjs-2";
import { graphs } from "styleConstants";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  GRAPHS_BORDER_COLORS_ARRAY,
  GRAPHS_COLORS_ARRAY,
} from "helpers/graphs";
import toast from "react-hot-toast";
import axios from "axios";
import { checkResponse } from "helpers/requests";
import { useAuthContext } from "contexts/AuthContext/context";
import { TextField } from "@mui/material";
import GraphLoading from "../GraphLoading";

export default function PurchasedCategoriesGraph() {
  // Get date
  const { authState } = useAuthContext();
  const { partner } = authState;
  const curDate = new Date();
  const curMonth = curDate.getMonth();
  const curYear = curDate.getFullYear();
  const firstDayDate = new Date(curYear, curMonth, 1);

  const [monthOnView, setMonthOnView] = React.useState(
    firstDayDate.toISOString().split("T")[0]
  );
  const [labels, setLabels] = React.useState<Array<string>>([]);
  const [values, setValues] = React.useState<Array<number>>([]);
  const [loading, setLoading] = React.useState(false);

  async function getData() {
    try {
      setLoading(true);

      // Make request
      const server = process.env.REACT_APP_SERVER_URL;
      const params = { date: monthOnView, securityID: partner?.id };
      const url = `${server}/graphs/products/purchased-categories`;
      const res = await axios.get(url, { params });

      // Checar respuesta
      if (!checkResponse(res)) throw res;

      const resData = res.data?.data?.categories;
      if (resData) {
        setLabels(resData.map((item: any) => item.name || "Sin nombre"));
        setValues(resData.map((item: any) => item.total));
      } else {
        setLabels([]);
        setValues([]);
      }

      setLoading(false);
    } catch (error) {
      console.log("GET PURCHASED CATEGORIES", error);
      toast.error("Hubo un error obteniendo los datos: Categorías Compradas");
    }
  }

  React.useEffect(() => {
    getData();
  }, [monthOnView]);

  return (
    <div className={graphs.graphContainer}>
      <div className={graphs.graphsTopContainer}>
        <h1 className={graphs.graphTitle}>Total de Categorías Compradas</h1>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={["month", "year"]}
            value={monthOnView}
            onChange={(newValue: any) => {
              const formatedDate = new Date(newValue)
                .toISOString()
                .split("T")[0];
              setMonthOnView(formatedDate);
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                helperText={null}
                size="small"
                sx={{ width: "300px" }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      {!loading ? (
        <div className={graphs.graphSmallBox}>
          <Chart
            options={{
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            }}
            type="doughnut"
            data={{
              labels,
              datasets: [
                {
                  data: values,
                  borderWidth: 2,
                  borderColor: GRAPHS_BORDER_COLORS_ARRAY,
                  backgroundColor: GRAPHS_COLORS_ARRAY,
                },
              ],
            }}
          />
        </div>
      ) : (
        <GraphLoading />
      )}
    </div>
  );
}
