import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  DialogContentText,
  Chip,
} from "@mui/material";
import { getCustomerOrders } from "actions/OrdersActions";
import { daysDifference } from "helpers/dateFormater";
import { orderStatus } from "helpers/orderStatus";
import React from "react";
import toast from "react-hot-toast";
import { OrderType, orderStatusKeys } from "types/Order";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { table } from "styleConstants";
import { useAppContext } from "contexts/AppContext/context";
import CloseIcon from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";

const styles = {
  sectionTitle:
    "mt-[10px] w-full border-b-[1px] border-b-solid border-b-gd text-start text-gd font-[500] text-[19px] mb-[35px]",
  th: "font-[500] text-gray-700 text-[14px] text-start pb-[10px] px-2",
  td: "text-[13px] text-start py-3 border-b border-b-300 px-2",
};

export default function CustomerOrders({
  customerId,
  status,
}: {
  customerId: string | null | undefined;
  status: string | null | undefined;
}) {
  const [customerOrders, setCustomerOrders] = React.useState<any[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const { appDispatch, appState } = useAppContext();
  const [selectedOrder, setSelectedOrder] = React.useState<OrderType | null>(
    null
  );
  const navigate = useNavigate();
  const { orderOnView } = appState;

  async function getCustomerOrdersHandler() {
    const res = await getCustomerOrders(customerId);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    const allOrders = res.orders;
    let activeOrders = [];
    if (status === orderStatusKeys.preparing_order) {
      activeOrders = allOrders.filter((o: OrderType) => {
        // If is the current order
        if (o?.order_id === orderOnView?.id) return false;
        // If the order is in support, don't show it
        if (o.is_in_support) return false;
        // Only return if the order valid prev order statuses
        if (
          o.status === orderStatusKeys.order_with_client ||
          o.status === orderStatusKeys.preparing_devolution
        )
          return true;
        return false;
      });
    }
    if (status === orderStatusKeys.preparing_devolution) {
      activeOrders = allOrders.filter((o: OrderType) => {
        // If is the current order
        if (o?.order_id === orderOnView?.id) return false;
        // If the order is in support, don't show it
        if (o.is_in_support) return false;
        // Only return if the order valid prev order statuses
        if (
          o.status === orderStatusKeys.assigning_worker ||
          o.status === orderStatusKeys.preparing_order
        )
          return true;
        return false;
      });
    }

    // Order by end of cycle
    const orderedOrders = activeOrders.sort((a: OrderType, b: OrderType) => {
      const A = a.end_of_cycle
        ? daysDifference(new Date(), new Date(a.end_of_cycle))
        : 100000;
      const B = b.end_of_cycle
        ? daysDifference(new Date(), new Date(b.end_of_cycle))
        : 100000;

      if (A > B) return 1;
      if (A < B) return -1;
      return 0;
    });
    setCustomerOrders(orderedOrders);
  }

  React.useEffect(() => {
    getCustomerOrdersHandler();
  }, []);

  return (
    <div
      className="shadow-bs4 rounded-[10px] py-[17px] md:px-[30px] px-[20px] mt-[30px]"
      id="productsList"
    >
      <h1 className={styles.sectionTitle}>Pedidos del Cliente</h1>
      {customerOrders.length ? (
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className={styles.th}>Días</th>
              <th className={styles.th}>Fin de Ciclo</th>
              <th className={styles.th}>Estado</th>
              <th className={styles.th}>Acción</th>
            </tr>
          </thead>
          <tbody>
            {customerOrders?.map((o) => {
              let diffDays: number | string = 0;
              let diffDayColor: string = table.fields.date;

              if (o?.end_of_cycle) {
                diffDays = daysDifference(new Date(), new Date(o.end_of_cycle));
                if (diffDays < 5) diffDayColor = table.fields.error;
                else diffDayColor = table.fields.success;
              } else diffDays = "-";

              const selected = selectedOrder?.order_id === o?.order_id;

              return (
                <tr
                  key={o?.order_id}
                  className={selected ? "bg-[#ecffec]" : ""}
                >
                  <td className={`${styles.td} ${diffDayColor}`}>
                    {diffDays} days
                  </td>
                  <td className={`${styles.td} ${diffDayColor}`}>
                    {o.end_of_cycle
                      ? new Date(o.end_of_cycle)
                          .toLocaleString()
                          .substring(0, 9)
                      : "Sin fecha"}
                  </td>
                  <td className={`${styles.td} ${diffDayColor}`}>
                    <Chip
                      label={orderStatus(o.status)?.chip.label}
                      variant="outlined"
                      size="small"
                      // @ts-ignore
                      color={orderStatus(o.status)?.chip.color}
                    />
                  </td>
                  <td className={styles.td}>
                    {status === orderStatusKeys.preparing_order &&
                      (!selected ? (
                        <IconButton
                          size="small"
                          color="success"
                          sx={{ ml: 1 }}
                          onClick={() => {
                            setOpenConfirmModal(true);
                            setSelectedOrder(o);
                          }}
                        >
                          <TaskAltIcon fontSize="small" />
                        </IconButton>
                      ) : (
                        <IconButton
                          size="small"
                          color="error"
                          sx={{ ml: 1 }}
                          onClick={() => {
                            appDispatch({
                              type: "setPrevOrder",
                              payload: null,
                            });
                            setSelectedOrder(null);
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      ))}
                    {status === orderStatusKeys.preparing_devolution && (
                      <IconButton
                        size="small"
                        sx={{ ml: 1 }}
                        onClick={() => {
                          // Clean current order
                          appDispatch({
                            type: "setOrderOnView",
                            payload: null,
                          });
                          navigate(`/private/orders/view/${o?.order_id}`);
                        }}
                      >
                        <RemoveRedEyeIcon fontSize="small" />
                      </IconButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className="mb-8 mt-11 text-[14px] text-gray-400">
          {status === orderStatusKeys.preparing_order &&
            "No hay pedidos por devolver"}
          {status === orderStatusKeys.preparing_devolution &&
            "No hay pedidos por enviar"}
        </p>
      )}

      {/* Confirm modal */}
      <Dialog
        open={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false);
          setSelectedOrder(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">
            Ligar Envío con Devolución
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este pedido seleccionado será devuelto en el envio del nuevo pedido.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmModal(false);
              setSelectedOrder(null);
            }}
            color="error"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              appDispatch({ type: "setPrevOrder", payload: selectedOrder });
              setOpenConfirmModal(false);
            }}
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
