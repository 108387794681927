import BackButton from "components/General/BackButton";
import React from "react";
import { divs, texts } from "styleConstants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { ButtonEl } from "styledComponents/button";
import { useAppContext } from "contexts/AppContext/context";
import capitalize from "helpers/capitalize";
import { validateInputs } from "helpers/requests";
import toast from "react-hot-toast";
import {
  addCategory,
  addSubcategory,
  deleteCategory,
  deleteSubcategory,
  editCategory,
  editSubcategory,
} from "actions/CategoryActions";
import { CategoryType, SubcategoryType } from "types/Model";
import { useAuthContext } from "contexts/AuthContext/context";

const styles = {
  list: {
    container:
      "xl:w-[550px] md:w-[500px] w-[90%] shadow-bs3 mt-[20px] flex items-center flex-col pb-[40px] rounded-[10px]",
    titles: {
      container:
        "flex md:w-[80%] w-[85%] mt-[40px] border-b-solid border-b-gd border-b-[1px]",
      h1: "md:text-[19px] text-[17px] text-gr3 w-[50%] font-[500] cursor-pointer transition-all duration-[200] hover:scale-[1.05] pb-[5px]",
    },
    ul: "md:w-[80%] w-[85%] mt-[20px]",
    li: {
      container:
        "flex items-center border-b-solid border-b-gr5 border-b-[1px] h-[50px] md:px-[10px] px-[0px]",
      text: "text-[17px] grow text-start",
    },
  },
};

export default function CategoriesView() {
  // Variables
  const { appState, appDispatch } = useAppContext();
  const { categories, subcategories } = appState;
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [section, setSection] = React.useState("categories");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openAddOrEditModal, setOpenAddOrEditModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<
    string | null | undefined
  >("");
  const [modalInput, setModalInput] = React.useState<string | null | undefined>(
    ""
  );

  // Add category handler
  async function addCategoryHandler() {
    // Validate
    if (!validateInputs([modalInput])) {
      toast.error("El nombre no es válido");
      return;
    }

    const body = { name: modalInput, securityID: partner?.id };

    // Add category or subcategory
    if (section === "categories") {
      const res = await addCategory(body);
      if (!res.success) {
        toast.error("Hubo un error agregando la categoría");
        return;
      }

      // Add to context
      const newObj: CategoryType = { name: modalInput, id: res.categoryId };
      if (categories) {
        const newCategories = [...categories, newObj];
        appDispatch({
          type: "setCategories",
          payload: newCategories,
        });
      }
    }
    if (section === "subcategories") {
      const res = await addSubcategory(body);
      if (!res.success) {
        toast.error("Hubo un error agregando la subcategoría");
        return;
      }

      // Add to context
      const newObj: SubcategoryType = {
        name: modalInput,
        id: res.subcategoryId,
      };
      if (subcategories) {
        const newSubcategories = [...subcategories, newObj];
        appDispatch({
          type: "setSubcategories",
          payload: newSubcategories,
        });
      }
    }

    // Close modal
    toast.success("Agregada correctamente");
    setOpenAddOrEditModal(false);
    setModalInput("");
  }

  // Edit category handler
  async function editCategoryHandler() {
    // Validate
    if (!validateInputs([modalInput, selectedItem])) {
      toast.error("El nombre no es válido");
      return;
    }

    const body = { name: modalInput, securityID: partner?.id };

    // Edit category or subcategory
    if (section === "categories") {
      const res = await editCategory(selectedItem, body);
      if (!res.success) {
        toast.error("Hubo un error editando la categoría");
        return;
      }

      // Add to context
      const newCategories = categories?.map((category) => {
        if (category.id === selectedItem)
          return { name: modalInput, id: category.id };
        return category;
      });
      if (newCategories) {
        appDispatch({
          type: "setCategories",
          payload: newCategories,
        });
      }
    }
    if (section === "subcategories") {
      const res = await editSubcategory(selectedItem, body);
      if (!res.success) {
        toast.error("Hubo un error editando la subcategoría");
        return;
      }

      // Add to context
      const newSubcategories = subcategories?.map((subcategory) => {
        if (subcategory.id === selectedItem)
          return { name: modalInput, id: subcategory.id };
        return subcategory;
      });
      if (newSubcategories) {
        appDispatch({
          type: "setSubcategories",
          payload: newSubcategories,
        });
      }
    }

    // Close modal
    toast.success("Editada correctamente");
    setOpenAddOrEditModal(false);
    setModalInput("");
  }

  // Delete category handler
  async function deleteCategoryHandler() {
    // Validate
    if (!validateInputs([selectedItem])) {
      toast.error("Error seleccionando elemento");
      return;
    }

    // Delete category or subcategory
    if (section === "categories") {
      const res = await deleteCategory(selectedItem, partner?.id);
      if (!res.success) {
        toast.error("Hubo un error eliminando la categoría");
        return;
      }

      // Set new context
      const newCategories = categories?.filter(
        (category) => category.id !== selectedItem
      );
      if (newCategories) {
        appDispatch({
          type: "setCategories",
          payload: newCategories,
        });
      }
    }
    if (section === "subcategories") {
      const res = await deleteSubcategory(selectedItem, partner?.id);
      if (!res.success) {
        toast.error("Hubo un error eliminando la subcategoría");
        return;
      }

      // Set new context
      const newSubcategories = subcategories?.filter(
        (subcategory) => subcategory.id !== selectedItem
      );
      if (newSubcategories) {
        appDispatch({
          type: "setSubcategories",
          payload: newSubcategories,
        });
      }
    }

    // Close modal
    toast.success("Eliminada correctamente");
    setOpenDeleteModal(false);
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models" />
        <h1 className={texts.pageTitle.h1}>Categorías</h1>
      </div>

      {/* Container */}
      <div className={styles.list.container}>
        {/* Titles */}
        <div className={styles.list.titles.container}>
          <h1
            className={`category-active-title ${styles.list.titles.h1}`}
            id="title-categories"
            onClick={() => {
              setSection("categories"); // Set section
              // Get title elements
              const titleCategories =
                document.getElementById("title-categories");
              const titleSubcategories = document.getElementById(
                "title-subcategories"
              );
              // Add and remove classes
              titleCategories?.classList.add("category-active-title");
              titleSubcategories?.classList.remove("category-active-title");
            }}
          >
            Categorías
          </h1>
          <h1
            className={styles.list.titles.h1}
            id="title-subcategories"
            onClick={() => {
              setSection("subcategories"); // Set section
              // Get title elements
              const titleCategories =
                document.getElementById("title-categories");
              const titleSubcategories = document.getElementById(
                "title-subcategories"
              );
              // Add and remove classes
              titleSubcategories?.classList.add("category-active-title");
              titleCategories?.classList.remove("category-active-title");
            }}
          >
            Subcategorías
          </h1>
        </div>

        {/* Categories List */}
        {section === "categories" && (
          <ul className={styles.list.ul}>
            {categories?.map((c) => (
              <li className={styles.list.li.container} key={c.id}>
                <p className={styles.list.li.text}>{capitalize(c.name)}</p>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenAddOrEditModal(true);
                    setEditModalMode(true);
                    setModalInput(c.name);
                    setSelectedItem(c.id);
                  }}
                >
                  <EditIcon fontSize="small" color="success" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedItem(c.id);
                    setOpenDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="small" color="error" />
                </IconButton>
              </li>
            ))}
          </ul>
        )}

        {/* Subcategories List */}
        {section === "subcategories" && (
          <ul className={styles.list.ul}>
            {subcategories?.map((s) => (
              <li className={styles.list.li.container} key={s.id}>
                <p className={styles.list.li.text}>{capitalize(s.name)}</p>
                <IconButton
                  size="small"
                  onClick={() => {
                    setOpenAddOrEditModal(true);
                    setEditModalMode(true);
                    setModalInput(s.name);
                    setSelectedItem(s.id);
                  }}
                >
                  <EditIcon fontSize="small" color="success" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setSelectedItem(s.id);
                    setOpenDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="small" color="error" />
                </IconButton>
              </li>
            ))}
          </ul>
        )}

        {/* Add btn */}
        <ButtonEl
          className="mt-[40px]"
          onClick={() => {
            setOpenAddOrEditModal(true);
            setEditModalMode(false);
          }}
        >
          <AddOutlinedIcon className="icon-start" />
          Añadir Nueva
        </ButtonEl>
      </div>

      {/* Add or Edit Dialog */}
      <Dialog
        open={openAddOrEditModal}
        onClose={() => {
          setOpenAddOrEditModal(false);
          setModalInput("");
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px] md:w-[400px] w-full">
            {`${editModalMode ? "Editar" : "Añadir"} ${
              section === "categories" ? "Categoría" : "Subcategoría"
            }`}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1 mb-[30px]">
              Ingresa el nombre de la{" "}
              {section === "categories" ? "categoría" : "subcategoría"}
            </p>
          </DialogContentText>
          <TextField
            label="Nombre"
            variant="outlined"
            color="success"
            value={modalInput}
            onChange={(e) => {
              if (!e.target.value.includes("/")) {
                setModalInput(e.target.value);
              } else {
                toast.error("El nombre no puede contener '/'");
              }
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            onClick={() => {
              setOpenAddOrEditModal(false);
              setModalInput("");
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={editModalMode ? editCategoryHandler : addCategoryHandler}
            autoFocus
            color="success"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-semibold text-[17px]">
            Eliminar {section === "categories" ? "Categoría" : "Subcategoría"}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p className="text-gr1 mb-[20px]">
              ¿Seguro que quieres eliminar la{" "}
              {section === "categories" ? "categoría" : "subcategoría"}?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteCategoryHandler} autoFocus color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
