import styled from "styled-components";

export const ButtonEl = styled.button`
  /* Default */
  min-width: 100px;
  height: 40px;
  border-radius: 4px;
  padding: 0px 15px;
  color: white;
  outline: none;
  border: none;
  background-color: #268b47;
  cursor: pointer;
  transition: 200ms all;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--bs1);

  a {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.9;
  }

  .icon-start {
    margin-right: 7px;
  }

  .icon-end {
    margin-left: 7px;
  }

  /* Colors */
  &.gd {
    background-color: var(--gd);
  }
  &.gl {
    background-color: var(--gl);
  }
  &.yl {
    background-color: var(--yl);
  }
  &.gr {
    background-color: var(--gr1);
  }
  &.rd {
    background-color: var(--rd);
  }

  /* Sizes */
  &.sm {
    min-width: 80px;
    height: 30px;
    font-size: 13px;
  }
  &.lg {
    min-width: 120px;
    height: 45px;
    font-size: 17px;
  }
  &.fw {
    width: 100%;
  }

  /* Variants */
  &.outlined-gd {
    color: var(--gd);
    border: 1px solid var(--gd);
    background-color: #fff;
  }

  &.outlined-gr {
    color: var(--gr1);
    border: 1px solid var(--gr1);
    background-color: #fff;
  }

  &.outlined-rd {
    color: var(--rd);
    border: 1px solid var(--rd);
    background-color: #fff;
  }
`;

export const x = 0;
