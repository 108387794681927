import React from "react";
import toast from "react-hot-toast";
import { ButtonEl } from "styledComponents/button";
import { InputEl } from "styledComponents/input";
import {
  checkResponse,
  getResponseError,
  validateInputs,
} from "helpers/requests";
import axios from "axios";
import { divs, texts } from "styleConstants";
import BackButton from "components/General/BackButton";
import { useAuthContext } from "contexts/AuthContext/context";

export default function AddWorker() {
  // Variables
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [email, setEmail] = React.useState("");
  const [type, setType] = React.useState("worker");
  const [key, setKey] = React.useState("");
  const [keyCreated, setKeyCreated] = React.useState(false);

  // Add new user key
  async function addNewUserKey() {
    try {
      // Validate fields
      if (!validateInputs([email, type])) {
        toast.error("Debes completar los campos");
        return;
      }

      // Generate new doc
      const newDoc = { email, type };

      // Add document
      const server = process.env.REACT_APP_SERVER_URL;
      const url = `${server}/new-user-keys`;
      const res = await axios.post(url, newDoc, {
        params: { securityID: partner?.id },
      });

      // Check response
      if (!checkResponse(res)) throw res;

      // Change step and set key
      setKeyCreated(true);
      setKey(res.data.key);
    } catch (error) {
      console.log("NEW WORKER KEY ERROR:", error);
      toast.error(
        getResponseError(error, "Hubo un error creando la clave del usuario")
      );
    }
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/workers" />
        <h1 className={texts.pageTitle.h1}>Añadir Trabajador</h1>
      </div>

      {!keyCreated ? (
        <>
          {/* Form */}
          <div className={divs.inputsContainer}>
            <InputEl>
              <p>Correo Electrónico</p>
              <div>
                <input
                  placeholder="Escríba aquí"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </InputEl>

            <InputEl>
              <p>Tipo de Usuario</p>
              <div>
                <select value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="worker">Trabajador</option>
                  <option value="partner">Socio</option>
                </select>
              </div>
            </InputEl>
          </div>

          {/* Buttons */}
          <ButtonEl type="button" onClick={addNewUserKey}>
            Añadir Trabajador
          </ButtonEl>
        </>
      ) : (
        // Key result
        <div className="mt-[50px] border-[1px] border-solid border-gd p-[30px] rounded-[20px]">
          <p className="mb-[20px] text-[17px]">
            El nuevo usuario debe iniciar sesión con la clave:
          </p>
          <p className="text-[20px] font-bold mb-[0px]">{key}</p>
        </div>
      )}
    </div>
  );
}
