import BackButton from "components/General/BackButton";
import SubscriptionForm from "components/Subscriptions/SubscriptionForm";
import React from "react";
import { divs, texts } from "styleConstants";

export default function EditSubscription() {
  return (
    <div className={divs.pageContainer}>
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/subscriptions/view" />
        <h1 className={texts.pageTitle.h1}>Editar Suscripción</h1>
      </div>

      <SubscriptionForm mode="edit" />
    </div>
  );
}
