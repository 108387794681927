import { PartnerType } from "types/Partner";
import { AuthStateType } from "./context";

// Actions
export type AuthActionType =
  | { type: "login"; payload: PartnerType }
  | { type: "logout" };

// Reducer
export const authReducer = (
  state: AuthStateType,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    /* The login action type changes the partner value in the context */
    case "login":
      return {
        ...state,
        partner: action.payload,
      };

    /* The logout action type set the partner value in the context to null */
    case "logout":
      return {
        ...state,
        partner: null,
      };

    default:
      return state;
  }
};
