import "chart.js/auto";
import CanceledSubscriptionsGraph from "components/Graphs/Subscriptions/CanceledSubscriptionsGraph";
import DowngradeSubscriptionsGraph from "components/Graphs/Subscriptions/DowngradeSubscriptionsGraph";
import MonthSubscriptionsGraph from "components/Graphs/Subscriptions/MonthSubscriptionsGraph";
import NewSubscriptionsGraph from "components/Graphs/Subscriptions/NewSubscriptionsGraph";
import OutSubscriptionCreditsGraph from "components/Graphs/Subscriptions/OutSubscriptionCreditsGraph";
import { graphs } from "styleConstants";

export default function SubscriptionsSection() {
  return (
    <div className={graphs.sectionContainer}>
      <MonthSubscriptionsGraph />
      <NewSubscriptionsGraph />
      <DowngradeSubscriptionsGraph />
      <CanceledSubscriptionsGraph />
      <OutSubscriptionCreditsGraph />
    </div>
  );
}
