import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface AlertDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
  agreeText: string;
  agreeAction: () => void;
  disagreeText?: string;
  disagreeAction?: () => void;
  agreeColor?: string;
  extraContent?: JSX.Element;
  disagreeColor?: string;
}

export function AlertDialog({
  open,
  setOpen,
  title,
  description,
  agreeText,
  agreeAction,
  disagreeText,
  disagreeAction,
  agreeColor,
  extraContent,
  disagreeColor,
}: AlertDialogProps) {
  const handleClose = () => {
    if (disagreeAction) disagreeAction();
    setOpen(false);
  };

  const handleAgree = () => {
    agreeAction();
    setOpen(false);
  };

  if (!open) return <></>;
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="border-primary border-4 ">
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
            {extraContent}
          </DialogContent>
          <DialogActions>
            {disagreeText && (
              <Button onClick={handleClose}>
                <p className={disagreeColor ? disagreeColor : "text-primary"}>
                  {disagreeText}
                </p>
              </Button>
            )}
            <Button onClick={handleAgree} autoFocus>
              <p className={agreeColor}>{agreeText}</p>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
