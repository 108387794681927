import BackButton from "components/General/BackButton";
import { useAppContext } from "contexts/AppContext/context";
import React from "react";
import { nanoid } from "nanoid";
import { ReactSortable } from "react-sortablejs";
import { divs, texts } from "styleConstants";
import { ButtonEl } from "styledComponents/button";
import toast from "react-hot-toast";
import { getModelById, orderImages } from "actions/ModelsActions";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext/context";

export interface SortableImageType {
  bucketPath?: string | undefined;
  url?: string | undefined;
  id: string;
}

export default function OrderImages() {
  const { appState, appDispatch } = useAppContext();
  const { modelOnView, initialModels } = appState;
  const { authState } = useAuthContext();
  const { partner } = authState;
  const modelImgs: Array<SortableImageType> | void[] | undefined =
    modelOnView?.model_imgs?.map((img) => ({ ...img, id: nanoid() }));
  const [imgs, setImgs] = React.useState<Array<SortableImageType> | undefined>(
    modelImgs
  );
  const navigate = useNavigate();

  // Order Images Handler
  async function orderImagesHandler() {
    // Validate array
    if (!imgs || imgs.length <= 0) {
      toast.error("No hay imágenes seleccionadas");
      return;
    }

    // Just leve the bucketPaths
    const orderedBucketPaths = imgs.map((img) => ({
      path: img.bucketPath,
    }));

    const res = await orderImages(
      modelOnView?.model_id,
      orderedBucketPaths,
      partner?.id
    );
    if (!res.success) {
      toast.error(res?.msg);
      return;
    }
    toast.success("Se ordenaron las imágenes correctamente");

    // Get model data and set it in context
    const modelRes = await getModelById(modelOnView?.model_id);
    if (!modelRes.success) {
      toast.error(modelRes.msg);
      return;
    }
    appDispatch({ type: "setModelOnView", payload: modelRes.model }); // Set modelOnView
    if (initialModels) {
      // Delete model from context
      const fiteredModels = initialModels?.filter(
        (m) => m.model_id !== modelOnView?.model_id
      );
      // Add new model data again
      appDispatch({
        type: "setInitialModels",
        payload: [...fiteredModels, modelRes.model],
      });
    }
    navigate("/private/models/view"); // Go to the view of that model
  }

  return (
    <div className={divs.pageContainer}>
      {/* Page Title */}
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/models/images/view" />
        <h1 className={texts.pageTitle.h1}>Ordenar Imágenes del Modelo</h1>
      </div>

      {/* Actions */}
      <div className="md:w-[78%] w-full flex md:justify-between justify-center items-center mb-[15px] mt-[10px]">
        <p className="text-gr1 text-[15px] md:block hidden">
          Arrastra y suelta las imágenes en el orden que desees.
        </p>
        <ButtonEl className="mx-[5px] sm" onClick={orderImagesHandler}>
          Guardar Cambios
        </ButtonEl>
      </div>

      {/* Images Container */}
      <div className={divs.modelImagesPage.container}>
        <ReactSortable
          list={imgs}
          setList={setImgs}
          className={divs.modelImagesPage.grid}
        >
          {imgs?.map((img) => (
            <div className={divs.modelImagesPage.item} key={img?.bucketPath}>
              <img
                src={img?.url || "/imgs/no_img.png"}
                alt="Imagen de Modelo"
                className={divs.modelImagesPage.img}
              />
            </div>
          ))}
        </ReactSortable>
      </div>
    </div>
  );
}
