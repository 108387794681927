import {
  Box,
  Tab,
  Tabs,
  Chip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { getMessages } from "actions/UserActions";
import { useAuthContext } from "contexts/AuthContext/context";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import React from "react";
import toast from "react-hot-toast";
import { divs, table, texts } from "styleConstants";
import { ContactMessageType } from "types/ContactMessage";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { contactMessageTypeTranslator } from "helpers/translators";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "contexts/AppContext/context";

export default function MessagesList() {
  const [section, setSection] = React.useState<string>("contacted");
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [originalMsgs, setOriginalMsgs] = React.useState<
    Array<ContactMessageType>
  >([]);
  const [msgs, setMsgs] = React.useState<Array<ContactMessageType>>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { appDispatch } = useAppContext();

  function filterByContacted(messages: ContactMessageType[], value: boolean) {
    return messages.filter(
      (msg: ContactMessageType) => msg?.contacted === value
    );
  }

  async function getMessagesHandler() {
    const res = await getMessages(partner?.id || "");
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Filter active ones
    setOriginalMsgs(res?.data);
    const contactedMsgs = filterByContacted(res?.data, true);
    setMsgs(contactedMsgs);
    setLoaded(true);
  }

  React.useEffect(() => {
    getMessagesHandler();
  }, []);

  // On change section
  const changeSection = (event: React.SyntheticEvent, newValue: string) => {
    setSection(newValue);
    if (newValue === "contacted") {
      const contactedMsgs = filterByContacted(originalMsgs, true);
      setMsgs(contactedMsgs);
    }
    if (newValue === "no_contacted") {
      const noContactedMsgs = filterByContacted(originalMsgs, false);
      setMsgs(noContactedMsgs);
    }
  };

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Mensajes de Contacto</h1>
      </div>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Tabs value={section} onChange={changeSection}>
          <Tab value="contacted" label="Contactados" />
          <Tab value="no_contacted" label="No Contactados" />
        </Tabs>
      </Box>

      {loaded ? (
        <table className={table.container}>
          <thead>
            {/* Headers */}
            <tr className={`${table.row} h-[50px]`}>
              <th className={`${table.cell} ${table.th}`}>Método</th>
              <th className={`${table.cell} ${table.th}`}>Valor</th>
              <th className={`${table.cell} ${table.th}`}>Estado</th>
              <th className={`${table.cell} ${table.th}`}>Tipo</th>
              <th className={`${table.cell} ${table.th}`}>Detalles</th>
            </tr>
          </thead>
          <tbody>
            {msgs.map((msg: ContactMessageType) => {
              const buyClotheType = msg?.type === "buy_clothe";

              return (
                <tr key={msg?.id}>
                  <td className={`${table.cell} ${table.td}`}>
                    {msg?.contact_method}
                  </td>
                  <td
                    className={`${table.cell} ${table.td} ${table.fields.date}`}
                  >
                    {msg?.contact_value}
                  </td>
                  <td
                    className={`${table.cell} ${table.td} ${
                      msg?.contacted ? table.fields.success : table.fields.error
                    }`}
                  >
                    {msg?.contacted ? "Contactado" : "No contactado"}
                  </td>
                  <td className={`${table.cell} ${table.td}`}>
                    <Chip
                      label={contactMessageTypeTranslator(msg?.type)}
                      color={buyClotheType ? "info" : "warning"}
                      variant="outlined"
                      icon={
                        buyClotheType ? (
                          <AttachMoneyIcon fontSize="small" />
                        ) : (
                          <HelpOutlineIcon fontSize="small" />
                        )
                      }
                    />
                  </td>
                  <td className={`${table.cell} ${table.td}`}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigate("/private/contact-messages/view");
                        appDispatch({
                          type: "setMessageOnView",
                          payload: msg,
                        });
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon className={table.icon} />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="h-[300px] flex justify-center items-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
