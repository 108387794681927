import { createTheme } from "@mui/material/styles";

// Custom material UI theme
export const theme = createTheme({
  palette: {
    primary: {
      main: "#2e7d32",
    },
    info: {
      main: "#1976d2",
    },
    warning: {
      main: "#df7803",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', sans-serif",
  },
});
