import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { orderStatus } from "helpers/orderStatus";
import { orderFlow } from "types/Order";

interface OrderStepperInterface {
  orderStep: string;
}

export default function OrderStepper({ orderStep }: OrderStepperInterface) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<Array<any>>([]);
  const [smallView] = React.useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  // Create steps
  function createSteps() {
    // Get flow
    const flow = orderFlow;

    // Create steps data
    const stepsData: Array<any> = [];
    Object.keys(flow).forEach((stepKey, i) => {
      const stepData: { label: string; completed: boolean; index: number } = {
        label: orderStatus(stepKey).stepperLabel,
        completed: false,
        index: i,
      };

      // The current order step will be the active one, the previous ones will be completed
      if (i < flow[orderStep]) stepData.completed = true;

      // Add
      stepsData.push(stepData);
    });

    // Set steps
    setSteps(stepsData);

    // Set active step
    setActiveStep(flow[orderStep]);
  }

  // On render create steps
  React.useEffect(() => {
    createSteps();
  }, [orderStep]);

  return (
    <div className="mt-[20px] mb-[40px] xl:pl-[0px] pl-[40px] w-full">
      <Stepper
        activeStep={activeStep}
        alternativeLabel={!smallView}
        orientation={smallView ? "vertical" : "horizontal"}
      >
        {steps.slice(0, 7).map((step) => (
          <Step key={step.index} completed={step.completed} index={step.index}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
