import { getUsers } from "actions/UserActions";
import { cutDisplayText } from "helpers/string";
import React from "react";
import toast from "react-hot-toast";
import { divs, table, texts } from "styleConstants";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { ButtonEl } from "styledComponents/button";
import { Chip, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAuthContext } from "contexts/AuthContext/context";

// Create react functional component for the worker row of the table
function WorkerRow({ user }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr className={table.row} key={user.id}>
        <td className={`${table.cell} ${table.td} ${table.fields.asigned}`}>
          <PersonIcon className="mr-[10px] text-gl" />
          <p className="mt-[2px]">
            {user?.name ? cutDisplayText(user?.name, 15) : "Sin Nombre"}
          </p>
        </td>
        <td className={`${table.cell} ${table.td}`}>
          <Chip
            label={user?.type === "worker" ? "Trabajador" : "Socio"}
            variant="outlined"
            size="small"
            color={user?.type === "worker" ? "info" : "secondary"}
          />
        </td>
        <td className={`${table.cell} ${table.td}`}>
          {user?.email || "Sin Email"}
        </td>
        <td className={`${table.cell} ${table.td}`}>
          <IconButton size="small" onClick={handleClick}>
            <MoreVertIcon className={table.icon} />
          </IconButton>
        </td>
      </tr>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Eliminar</MenuItem>
      </Menu>
    </>
  );
}

export default function Workers() {
  // Variables
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [users, setUsers] = React.useState([]);

  async function getUsersHandler() {
    const res = await getUsers(partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    setUsers(res.users);
  }

  React.useEffect(() => {
    getUsersHandler();
  }, []);

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Trabajadores</h1>
      </div>

      <div className="flex mb-[20px] md:w-full w-[90%] flex-row justify-end">
        {/* Add Category Btn */}
        <Link to="/private/workers/add-worker" className="mr-[10px] xl:block">
          <ButtonEl className="outlined" type="button">
            <AddOutlinedIcon className="icon-start" />
            Agregar
          </ButtonEl>
        </Link>
      </div>

      <div className="xl:w-full w-[90%] xl:overflow-auto overflow-x-scroll mb-[40px]">
        <table className={table.container}>
          <thead>
            {/* Headers */}
            <tr className={`${table.row} h-[50px]`}>
              <th className={`${table.cell} ${table.th}`}>Nombre</th>
              <th className={`${table.cell} ${table.th}`}>Tipo</th>
              <th className={`${table.cell} ${table.th}`}>Email</th>
              <th className={`${table.cell} ${table.th}`}>Más</th>
            </tr>
          </thead>
          <tbody>
            {/* Items */}
            {users?.map((user: any) => (
              <WorkerRow user={user} key={user?.id} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
