import "chart.js/auto";
import PermanentlyPurchasedProductsGraph from "components/Graphs/Products/PermanentlyPurchasedProductsGraph";
import PurchasedCategoriesGraph from "components/Graphs/Products/PurchasedCategoriesGraph";
import PurchasedSubcategoriesGraph from "components/Graphs/Products/PurchasedSubcategoriesGraph";
import RentedCategoriesGraph from "components/Graphs/Products/RentedCategoriesGraph";
import RentedSubcategoriesGraph from "components/Graphs/Products/RentedSubcategoriesGraph";
import { graphs } from "styleConstants";

export default function ProductsSection() {
  return (
    <div className={graphs.sectionContainer}>
      <PermanentlyPurchasedProductsGraph />
      <RentedCategoriesGraph />
      <RentedSubcategoriesGraph />
      <PurchasedCategoriesGraph />
      <PurchasedSubcategoriesGraph />
    </div>
  );
}
