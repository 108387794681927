export function userTypeTranslator(type: string) {
  switch (type) {
    case "worker":
      return "Trabajador";
    case "partner":
      return "Socio";
    case "user":
      return "Cliente";
    default:
      return "Trabajador";
  }
}

export function modelSizeTranslator(size: string | null | undefined) {
  switch (size) {
    case "s":
      return "Chica";
    case "m":
      return "Mediana";
    case "M":
      return "Mediana";
    case "l":
      return "Grande";
    case "xl":
      return "Extra Grande";

    default:
      return size;
  }
}

export function booleanTranslate(status: boolean | undefined | null) {
  if (status) return "Si";
  return "No";
}

export function orderSupportCreatedByTranslator(
  code: string | undefined | null
) {
  switch (code) {
    case "worker":
      return "Trabajador";
    case "customer":
      return "Cliente";
    default:
      return "No Definido";
  }
}

export function deliveryTypeTranslator(code: string | undefined | null) {
  switch (code) {
    case "only_delivery":
      return "Solo Envío";
    case "delivery_with_return":
      return "Envío con Devolución";
    case "only_return":
      return "Solo Devolución";
    default:
      return "No Definido";
  }
}

export function contactMessageTypeTranslator(code: string | undefined | null) {
  switch (code) {
    case "buy_clothe":
      return "Compra de Ropa";
    case "question":
      return "Pregunta";
    default:
      return code;
  }
}
