import { Routes, Route } from "react-router-dom";
import PageLayout from "components/Layout/PageLayout";
import InitialRoute from "routes/InitialRoute";
import Login from "pages/Auth/Login";
import Profile from "pages/Profile/Profile";
import Models from "pages/Models/Models";
import AddModel from "pages/Models/AddModel";
import AddWorker from "pages/Workers/AddWorker";
import GraphsAndData from "pages/Graphs/GraphsAndData";
import PrivateRoute from "routes/PrivateRoute";
import EditProfile from "pages/Profile/EditProfile";
import ChangePassword from "pages/Profile/ChangePassword";
import ModelView from "pages/Models/ModelView";
import EditModel from "pages/Models/EditModel";
import Register from "pages/Auth/Register";
import OrderImages from "pages/Models/Images/OrderImages";
import ViewImages from "pages/Models/Images/ViewImages";
import AddImages from "pages/Models/Images/AddImages";
import DeleteImages from "pages/Models/Images/DeleteImages";
import CategoriesView from "pages/Models/CategoriesView";
import ResetPassword from "pages/Auth/ResetPassword";
import Orders from "pages/Orders/Orders";
import OrderView from "pages/Orders/OrderView";
import Subscriptions from "pages/Subscriptions/Subscriptions";
import SubscriptionView from "pages/Subscriptions/SubscriptionView";
import AddSubscription from "pages/Subscriptions/AddSubscription";
import EditSubscription from "pages/Subscriptions/EditSubscription";
import Workers from "pages/Workers/Workers";
import MessagesList from "pages/Messages/MessagesList";
import MessageDetails from "pages/Messages/MessageDetails";

import PageNotFound from "404";

// Component
export default function Router() {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="" element={<InitialRoute />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="reset-password" element={<ResetPassword />} />

      {/* Private Routes */}
      <Route path="private" element={<PrivateRoute />}>
        <Route
          path="profile"
          element={<PageLayout title="Mi Perfil" Page={Profile} />}
        />

        <Route
          path="profile/edit"
          element={<PageLayout title="Mi Perfil" Page={EditProfile} />}
        />

        <Route
          path="profile/change-password"
          element={<PageLayout title="Mi Perfil" Page={ChangePassword} />}
        />

        <Route
          path="models"
          element={<PageLayout title="Modelos" Page={Models} />}
        />

        <Route
          path="models/view"
          element={<PageLayout title="Modelos" Page={ModelView} />}
        />

        <Route
          path="models/add-model"
          element={<PageLayout title="Modelos" Page={AddModel} />}
        />

        <Route
          path="models/edit"
          element={<PageLayout title="Modelos" Page={EditModel} />}
        />

        <Route
          path="models/images/view"
          element={<PageLayout title="Modelos" Page={ViewImages} />}
        />

        <Route
          path="models/images/add"
          element={<PageLayout title="Modelos" Page={AddImages} />}
        />

        <Route
          path="models/images/order"
          element={<PageLayout title="Modelos" Page={OrderImages} />}
        />

        <Route
          path="models/images/delete"
          element={<PageLayout title="Modelos" Page={DeleteImages} />}
        />

        {/* Categories */}
        <Route
          path="categories"
          element={<PageLayout title="Categorías" Page={CategoriesView} />}
        />

        <Route
          path="orders"
          element={<PageLayout title="Pedidos" Page={Orders} />}
        />

        <Route
          path="orders/view/:orderId"
          element={<PageLayout title="Pedidos" Page={OrderView} />}
        />

        {/* Workers */}

        <Route
          path="workers"
          element={<PageLayout title="Trabajadores" Page={Workers} />}
        />

        <Route
          path="workers/add-worker"
          element={<PageLayout title="Trabajadores" Page={AddWorker} />}
        />

        <Route
          path="graphs"
          element={<PageLayout title="Gráficos" Page={GraphsAndData} />}
        />

        <Route
          path="subscriptions"
          element={<PageLayout title="Suscripciones" Page={Subscriptions} />}
        />

        <Route
          path="subscriptions/view"
          element={<PageLayout title="Suscripciones" Page={SubscriptionView} />}
        />

        <Route
          path="subscriptions/add"
          element={<PageLayout title="Suscripciones" Page={AddSubscription} />}
        />

        <Route
          path="subscriptions/edit"
          element={<PageLayout title="Suscripciones" Page={EditSubscription} />}
        />

        <Route
          path="contact-messages"
          element={<PageLayout title="Mensajes" Page={MessagesList} />}
        />

        <Route
          path="contact-messages/view"
          element={<PageLayout title="Mensajes" Page={MessageDetails} />}
        />
      </Route>
      <Route path="*" element={<PageNotFound></PageNotFound>} />
    </Routes>
  );
}
