import { divs, searchBar, texts } from "styleConstants";
import { SelectEl } from "styledComponents/select";
import { Link, useNavigate } from "react-router-dom";
import { ButtonEl } from "styledComponents/button";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ModelCard from "components/Models/ModelCard";
import React from "react";
import { validateInputs } from "helpers/requests";
import {
  getModels,
  getModelsByCategory,
  searchModel,
} from "actions/ModelsActions";
import { ModelType } from "types/Model";
import { useAppContext } from "contexts/AppContext/context";
import capitalize from "helpers/capitalize";
import LoadingComponent from "components/General/LoadingComponent";
import NoDataComponent from "components/General/NoDataComponent";
import { IconButton, Menu, MenuItem, Switch } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import toast from "react-hot-toast";

// Component
export default function Models() {
  // Variables
  const { appState } = useAppContext();
  const { categories, initialModels } = appState;
  const [models, setModels] = React.useState<
    Array<ModelType> | null | undefined
  >(
    initialModels // The initial models are gotten when the page is loading
  );
  const [query, setQuery] = React.useState("");
  const [moreMenuAnchor, setMoreMenuAnchor] = React.useState(null);
  const openMoreMenu = Boolean(moreMenuAnchor);
  const navigate = useNavigate();
  const [category, setCategory] = React.useState("");

  // Pagination

  const [curLastModel, setCurLastModel] = React.useState(null);

  const [filterAvailableClothesSwitch, setFilterAvailableClothesSwitch] =
    React.useState(false);

  // Filtering control States
  const [showSearchBar, setShowSearchBar] = React.useState(true);
  const [showCategories, setShowCategories] = React.useState(true);
  const [showFilterToggle, setShowFilterToggle] = React.useState(true);
  const [showPagination, setShowPagination] = React.useState(true);

  function showSearchFilters() {
    setShowSearchBar(true);
    setShowCategories(false);
    setShowFilterToggle(false);
    setShowPagination(false);
  }

  function showCategoryFilters() {
    setShowSearchBar(false);
    setShowCategories(true);
    setShowPagination(false);
  }

  //When Toggle and pagination needs to be shown.
  function showAllModelsFilter() {
    setShowSearchBar(false);
    setShowCategories(false);
    setShowFilterToggle(false);
    setShowPagination(true);
    setQuery("");
  }

  function showToggleFilter() {
    setShowSearchBar(false);
    setShowCategories(false);
    setShowFilterToggle(true);
    setShowPagination(true);
  }

  function resetAllFilters() {
    setShowSearchBar(true);
    setShowCategories(true);
    setShowFilterToggle(true);
    setShowPagination(true);
    setModels(null);
    setCategory("");
    getModelsHandler();
  }

  // Search model handler
  async function searchModelHandler(e: any) {
    showSearchFilters();
    // Prevent reload
    e.preventDefault();

    // If query is empty return
    if (!validateInputs([query])) {
      return;
    }

    // Search query
    setModels(null); // Show loading gif
    const res = await searchModel(query);
    if (!res.success) {
      toast.error(res.msg); // Show error
      setModels([]); // Set models empty
    }
    setModels(res.models);
  }

  // Filter by category handler
  async function filterByCategoryHandler(e: any) {
    const category = e.target.value;
    setCategory(category);
    // If category is empty return
    if (!validateInputs([category])) {
      return;
    }

    // If category id is all, get all models
    if (category === "_all") {
      resetAllFilters();
      setModels(initialModels);
      return;
    }

    showCategoryFilters();
    // Get category models
    setModels(null); // Show loading gif
    const res = await getModelsByCategory(
      category,
      filterAvailableClothesSwitch
    );
    if (!res.success) {
      toast.error(res.msg); // Show error
      setModels([]); // Set models empty
    } else {
      setModels(res.models);
    }
  }

  async function getNextModels() {
    const docsPerPage = 5;
    let res: any = {};
    showAllModelsFilter();
    res = await getModels(
      "next",
      docsPerPage,
      curLastModel,
      filterAvailableClothesSwitch
    );

    if (res.models.length) {
      setCurLastModel(res.models[res.models.length - 1].name);
    }

    // Check if next page is available
    if (res.models.length < docsPerPage || res.models.length === 0) {
      setShowPagination(false);
    }

    res = models?.concat(res.models);
    setModels(res);
  }

  async function getModelsHandler() {
    const docsPerPage = 5;
    let res: any = {};
    res = await getModels(
      null,
      docsPerPage,
      null,
      filterAvailableClothesSwitch
    );
    // Handle error
    if (!res.success) {
      toast.error(res.msg); // Show error
      return;
    }

    // Success
    setModels(res.models);
    //setModels(res.models);
    if (res.models.length) {
      setCurLastModel(res.models[res.models.length - 1].name);
    }
  }

  // Render everytime initialModels changes
  React.useEffect(() => {
    // Check if user is filtering by category or not
    if (category.length <= 0) {
      getModelsHandler();
    } else {
      filterByCategoryHandler({ target: { value: category } });
    }
  }, [initialModels, filterAvailableClothesSwitch]);

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Modelos</h1>
      </div>

      <div className="flex mb-[40px] md:w-full w-[90%] md:flex-row flex-col ">
        <form
          className="flex grow md:flex-row flex-col"
          onSubmit={searchModelHandler}
        >
          {/* Search Bar */}
          <div
            className={
              `${searchBar.container} md:mb-[0px] mb-[10px]` +
              (showSearchBar ? "" : " invisible")
            }
          >
            <input
              className={searchBar.input}
              type="text"
              placeholder="Buscar modelo"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button className={searchBar.button} type="submit">
              <SearchOutlinedIcon className={searchBar.icon} />
            </button>
          </div>

          {/* Categories */}
          {showCategories && (
            <SelectEl
              className="smw-[170px]"
              defaultValue=""
              onChange={filterByCategoryHandler}
            >
              <option value="_all">Todos</option>
              {categories?.map((c) => (
                <option value={c?.id} key={c?.id}>
                  {capitalize(c?.name)}
                </option>
              ))}
            </SelectEl>
          )}
        </form>
        {/* Available Clothes Switch */}
        {showFilterToggle && (
          <div className="max-w-[150px]">
            <p>Modelos Disponibles</p>
            <Switch
              checked={filterAvailableClothesSwitch}
              onChange={(e) => {
                showToggleFilter();
                setFilterAvailableClothesSwitch(e.target.checked);
              }}
            />
          </div>
        )}
        <ButtonEl className="outlined" type="button" onClick={resetAllFilters}>
          Restablecer
        </ButtonEl>
        {/* Add Model Btn */}
        <Link
          to="/private/models/add-model"
          className="mx-[10px] xl:block hidden"
        >
          <ButtonEl className="outlined" type="button">
            <AddOutlinedIcon className="icon-start" />
          </ButtonEl>
        </Link>

        {/* More */}
        <div className="md:w-auto w-full flex justify-end md:mt-[0px] mt-[10px]">
          {/* @ts-ignore */}
          <IconButton onClick={(e) => setMoreMenuAnchor(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        </div>

        {/* Menu */}
        <Menu
          id="basic-menu"
          anchorEl={moreMenuAnchor}
          open={openMoreMenu}
          onClose={() => setMoreMenuAnchor(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            className="w-[150px]"
            onClick={() => {
              navigate("/private/models/add-model");
              setMoreMenuAnchor(null);
            }}
          >
            Añadir Modelo
          </MenuItem>
          <MenuItem
            className="w-[150px]"
            onClick={() => {
              navigate("/private/categories");
              setMoreMenuAnchor(null);
            }}
          >
            Categorías
          </MenuItem>
        </Menu>
      </div>

      {/* Models Container */}
      {(() => {
        if (!models) return <LoadingComponent />;
        if (models && models?.length <= 0) return <NoDataComponent />;
        return (
          <div className="w-full grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2">
            {models?.map((m: any) => (
              <ModelCard key={m.model_id} model={m} />
            ))}
          </div>
        );
      })()}

      {/* Pagination */}
      {showPagination && (
        <ButtonEl
          onClick={() => {
            getNextModels();
          }}
        >
          Cargar más
        </ButtonEl>
      )}
    </div>
  );
}
