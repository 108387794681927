import BackButton from "components/General/BackButton";
import { useAppContext } from "contexts/AppContext/context";
import { booleanTranslate } from "helpers/translators";
import React from "react";
import { IconButton } from "@mui/material";
import { divs, texts } from "styleConstants";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const styles = {
  detail: "flex text-[16px] mb-[10px] items-center",
  label: "font-medium text-gd mr-[10px] w-[150px] text-start",
  input: "px-[5px] border-gd border-b-[1px] outline-none grow",
};

export default function SubscriptionView() {
  const { appState } = useAppContext();
  const { subscriptionOnView: subscription } = appState;

  return (
    <div className={divs.pageContainer}>
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/subscriptions" />
        <h1 className={texts.pageTitle.h1}>Detalles de Suscripción</h1>
      </div>

      <div className="w-[500px] shadow-bs3 mt-[20px] rounded-md relative overflow-hidden">
        <Link to="/private/subscriptions/edit">
          <IconButton className="edit-model-view-btn top-[15px] right-[15px] z-20">
            <EditIcon />
          </IconButton>
        </Link>

        <div className="w-full h-[110px] flex relative items-center justify-center">
          <img
            src="/imgs/squared-bg-blue.png"
            alt="Bg"
            className="w-full h-full object-cover absolute top-0 left-0"
          />
          <h1 className="text-black font-[600] text-[28px] z-10">
            {subscription?.name || "Sin información"}
          </h1>
        </div>

        <div className="p-[30px]">
          <p className="text-gr2 text-[14px] mb-[30px] text-start">
            {subscription?.description || "Sin información"}
          </p>

          <div className={styles.detail}>
            <p className={styles.label}>Cobro Mensual:</p>
            <p>
              {subscription?.monthly_fee
                ? `$${subscription?.monthly_fee}`
                : "Sin información"}
            </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Créditos:</p>
            <p>{subscription?.credit_ammount || "Sin información"}</p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Visible:</p>
            <p>{booleanTranslate(subscription?.show_to_users)}</p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Id Stripe:</p>
            <p>{subscription?.stripe_price_id || "Sin información"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
