import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

export async function getAllSubscriptions() {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/subscriptions`;
    const params = { all: true };
    const res = await axios.get(url, { params });

    // Check errors
    if (!checkResponse(res)) throw res;

    // Set states
    return { success: true, subscriptions: res?.data?.subscriptions };
  } catch (error) {
    console.log("GET SUBSCRIPTIONS ERROR", error);
    return {
      success: false,
      msg: getResponseError(
        error,
        "Hubo un error obteniendo las suscripciones"
      ),
    };
  }
}

export async function editSubscription(
  subscriptionId: string | null | undefined,
  body: any,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const params = {
      securityID,
    };
    const url = `${server}/subscriptions/${subscriptionId}`;
    const res = await axios.put(url, body, { params });

    // Check errors
    if (!checkResponse(res)) throw res;

    // Set states
    return { success: true };
  } catch (error) {
    console.log("UPDATE SUBSCRIPTION ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error actualizando la suscripción"),
    };
  }
}

export async function addSubscription(
  body: any,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const params = {
      securityID,
    };
    const url = `${server}/subscriptions`;
    const res = await axios.post(url, body, { params });

    // Check errors
    if (!checkResponse(res)) throw res;

    // Set states
    return { success: true };
  } catch (error) {
    console.log("CREATE SUBSCRIPTION ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error añadiendo la suscripción"),
    };
  }
}

export async function deleteSubscription(
  subscriptionId: string | null | undefined,
  securityID: string | null | undefined
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const params = {
      securityID,
    };
    const url = `${server}/subscriptions/${subscriptionId}`;
    const res = await axios.delete(url, { params });

    // Check errors
    if (!checkResponse(res)) throw res;

    // Set states
    return { success: true };
  } catch (error) {
    console.log("DELETE SUBSCRIPTION ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error al eliminar la suscripción"),
    };
  }
}
