import React from "react";
import { CategoryType, ModelType, SubcategoryType } from "types/Model";
import { OrderType } from "types/Order";
import { SubscriptionType } from "types/Subscription";
import { appReducer, AppActionType } from "./reducer";
import { ContactMessageType } from "types/ContactMessage";

// Interfaces and types
export interface AppStateType {
  initialModels: Array<ModelType> | null;
  categories: Array<CategoryType> | null;
  subcategories: Array<SubcategoryType> | null;
  modelOnView: ModelType | null;
  orderOnView: OrderType | null;
  subscriptionOnView: SubscriptionType | null;
  prevOrder: OrderType | null;
  messageOnView: ContactMessageType | null;
}

export type AppContextType = {
  appState: AppStateType;
  appDispatch: React.Dispatch<AppActionType>;
};

// Default State
const defaultState: AppStateType = {
  initialModels: null,
  modelOnView: null,
  categories: null,
  subcategories: null,
  orderOnView: null,
  subscriptionOnView: null,
  prevOrder: null,
  messageOnView: null,
};

// Create context
export const AppContext = React.createContext<AppContextType>(
  {} as AppContextType
);

// Provider
export default function AppProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Create app state and dispatch
  const [appState, appDispatch] = React.useReducer(appReducer, defaultState);

  return (
    <AppContext.Provider
      value={{
        appState,
        appDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

// Use app hook
export const useAppContext = () => {
  return React.useContext(AppContext);
};
