import axios from "axios";
import { checkResponse, getResponseError } from "helpers/requests";

// Function to get users
export async function getUsers(securityID: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/partners/workers-and-partners`;
    const res = await axios.get(url, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return users
    return {
      success: true,
      users: res?.data?.users || [],
    };
  } catch (error) {
    console.log("GET ALL USERS ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo los trabajadores"),
    };
  }
}

export async function getMessages(securityID: string | null | undefined) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/users/contact-us`;
    const res = await axios.get(url, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return users
    return {
      success: true,
      data: res?.data?.data || [],
    };
  } catch (error) {
    console.log("GET ALL CONTACT MESSAGES ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error obteniendo los mensajes"),
    };
  }
}

export async function updateContactMessage(
  securityID: string | null | undefined,
  messageId: string | null | undefined,
  body: any
) {
  try {
    // Make request
    const server = process.env.REACT_APP_SERVER_URL;
    const url = `${server}/users/contact-us/${messageId}`;
    const res = await axios.put(url, body, { params: { securityID } });

    // Check response
    if (!checkResponse(res)) throw res;

    // Return users
    return {
      success: true,
    };
  } catch (error) {
    console.log("UPDATE MESSAGE ERROR", error);
    return {
      success: false,
      msg: getResponseError(error, "Hubo un error al actualizar el mensaje"),
    };
  }
}
