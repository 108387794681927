import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface BackButtonInterface {
  path?: string;
}

export default function BackButton({ path }: BackButtonInterface) {
  // Variables
  const navigate = useNavigate();

  return (
    <IconButton
      className="back_button"
      onClick={() => {
        if (path) navigate(path);
        else navigate(-1);
      }}
    >
      <KeyboardBackspaceIcon />
    </IconButton>
  );
}

BackButton.defaultProps = {
  path: null,
};
