import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { divs, searchBar, texts } from "styleConstants";
import React from "react";
import {
  getAllWorkersOrders,
  getInSupportOrders,
  getOrdersByStatus,
} from "actions/OrdersActions";
import toast from "react-hot-toast";
import OrdersTable from "components/Orders/OrdersTable";
import { orderStatusKeys, OrderType } from "types/Order";
import { Switch, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { orderOrders, orderOrdersByEndOfCycle } from "helpers/order";
import { useAuthContext } from "contexts/AuthContext/context";
import { orderStatus } from "helpers/orderStatus";

// Component
export default function Orders() {
  const [orders, setOrders] = React.useState<Array<OrderType>>([]);
  const [originalOrders, setOriginalOrders] = React.useState<Array<OrderType>>(
    []
  );
  const { authState } = useAuthContext();
  const { partner } = authState;
  const [loading, setLoading] = React.useState(true);
  const [section, setSection] = React.useState("in_support");
  const [orderByEndOfCycle, setOrderByEndOfCycle] = React.useState(false);
  const statuses = Object.keys(orderStatusKeys);
  const [status, setStatus] = React.useState("all");

  async function getInSupportOrdersHandler() {
    setLoading(true);
    const res = await getInSupportOrders(partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Set states
    setOrders(orderOrders(res.orders));
    setLoading(false);
  }

  async function getPendingOrdersHandler() {
    setLoading(true);
    const res = await getOrdersByStatus(
      orderStatusKeys.assigning_worker,
      partner?.id
    );
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Set states
    setOrders(orderOrders(res.orders));
    setLoading(false);
  }

  async function getAllOrdersHandler() {
    setLoading(true);
    const res = await getAllWorkersOrders(partner?.id);
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Set states
    setOrders(orderOrders(res.orders));
    setOriginalOrders(orderOrders(res.orders));
    setLoading(false);
  }

  // On change section
  const changeSection = (event: React.SyntheticEvent, newValue: string) => {
    setOrderByEndOfCycle(false);
    setSection(newValue);
    if (newValue === "in_support") {
      getInSupportOrdersHandler();
    }
    if (newValue === "all_orders") {
      getAllOrdersHandler();
    }
    if (newValue === "pending") {
      getPendingOrdersHandler();
    }
  };

  // On render
  React.useEffect(() => {
    getInSupportOrdersHandler();
  }, []);

  React.useEffect(() => {
    if (orderByEndOfCycle) {
      const newOrders = orderOrdersByEndOfCycle(orders);
      setOrders([...newOrders]);
    } else {
      const newOrders = orderOrders(orders);
      setOrders([...newOrders]);
    }
  }, [orderByEndOfCycle]);

  React.useEffect(() => {
    setOrderByEndOfCycle(false);
    if (status === "all") {
      setOrders(originalOrders);
    } else {
      const newOrders = originalOrders.filter((o) => o.status === status);
      setOrders(newOrders);
    }
  }, [status]);

  return (
    <div className={divs.pageContainer}>
      {/* Title */}
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Pedidos</h1>
      </div>

      {/* Search Bar */}
      <div className="mb-[30px] xl:w-full w-[90%]">
        <div className={searchBar.container}>
          <input
            type="text"
            placeholder="Buscar pedido"
            className={searchBar.input}
          />
          <button type="button" className={searchBar.button}>
            <SearchOutlinedIcon className={searchBar.icon} />
          </button>
        </div>
      </div>

      {/* Sections */}
      <div className="mb-[20px] xl:w-full w-[90%]">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs value={section} onChange={changeSection}>
            <Tab value="in_support" label="En Soporte" />
            <Tab value="all_orders" label="Todas las Ordenes" />
            <Tab value="pending" label="Pendientes" />
          </Tabs>

          <div className="flex items-center justify-center">
            <p className="text-gd text-[14px] mr-3">Fin de Ciclo</p>
            <Switch
              checked={orderByEndOfCycle}
              onChange={(e) => setOrderByEndOfCycle(e.target.checked)}
            />
            {section === "all_orders" && (
              <select
                className="text-gd outline-none w-[150px] text-[14px] ml-5"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="all">Todos</option>
                {statuses.map((s) => (
                  <option key={s} value={s}>
                    {orderStatus(s).chip.label}
                  </option>
                ))}
              </select>
            )}
          </div>
        </Box>
      </div>

      {/* List Container */}
      <div className="xl:w-full w-[90%] xl:overflow-auto overflow-x-scroll mb-[40px]">
        <OrdersTable orders={orders} loading={loading} />
      </div>
    </div>
  );
}
