import { updateContactMessage } from "actions/UserActions";
import BackButton from "components/General/BackButton";
import { useAppContext } from "contexts/AppContext/context";
import { useAuthContext } from "contexts/AuthContext/context";
import { contactMessageTypeTranslator } from "helpers/translators";
import React from "react";
import toast from "react-hot-toast";
import { divs, texts } from "styleConstants";

const styles = {
  detail: "flex text-[16px] mb-[15px]",
  label: "font-medium text-gd mr-[10px] w-[33%] text-start",
  input: "px-[5px] border-gd border-b-[1px] outline-none grow",
  value: "w-[60%] text-start",
};

export default function MessageDetails() {
  const { appState } = useAppContext();
  const { authState } = useAuthContext();
  const { messageOnView } = appState;
  const { partner } = authState;
  const [contacted, setContacted] = React.useState(
    messageOnView?.contacted ? "contacted" : "no_contacted"
  );

  async function updateMessageStatusHanler(value: string) {
    const body = { contacted: value === "contacted" };
    const res = await updateContactMessage(
      partner?.id,
      messageOnView?.id,
      body
    );

    if (!res.success) {
      toast.error("Hubo un error actualizando el estado del mensaje");
      return;
    }

    toast.success("Mensaje actualizado");
  }

  return (
    <div className={divs.pageContainer}>
      <div className={texts.pageTitle.container}>
        <BackButton path="/private/contact-messages" />
        <h1 className={texts.pageTitle.h1}>Detalles de Mensaje</h1>
      </div>

      <div className="w-[500px] shadow-bs3 mt-[20px] rounded-md relative overflow-hidden">
        <div className="w-full h-[90px] flex relative items-center justify-center">
          <img
            src="/imgs/squared-bg-green.png"
            alt="Bg"
            className="w-full h-full object-cover absolute top-0 left-0 opacity-20"
          />
          <h1 className="text-black font-[600] text-[25px] z-10">
            Mensaje de Contacto
          </h1>
        </div>

        <div className="p-[30px]">
          <div className={styles.detail}>
            <p className={styles.label}>Método:</p>
            <p className={styles.value}>{messageOnView?.contact_method}</p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Valor:</p>
            <p className={styles.value}>{messageOnView?.contact_value}</p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Tipo:</p>
            <p className={styles.value}>
              {contactMessageTypeTranslator(messageOnView?.type)}
            </p>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Estado:</p>
            <select
              className="ml-[-5px] w-[170px] outline-none"
              value={contacted}
              onChange={(e) => {
                setContacted(e.target.value);
                updateMessageStatusHanler(e.target.value);
              }}
            >
              <option value="contacted">Contactado</option>
              <option value="no_contacted">No Contactado</option>
            </select>
          </div>
          <div className={styles.detail}>
            <p className={styles.label}>Mensaje:</p>
            <p>{messageOnView?.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
