import React from "react";

export default function NoDataComponent() {
  return (
    <div className="w-full flex flex-col items-center">
      <img
        className="w-[40%] mt-[5%]"
        src="/imgs/no_data.svg"
        alt="No Hay Resultados"
      />
      <p className="text-slate-500 text-[17px]">No se encontraron resultados</p>
    </div>
  );
}
