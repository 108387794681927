import { OrderType } from "types/Order";
import { daysDifference } from "./dateFormater";

// Sort orders by date
export function orderOrders(orders: Array<OrderType>) {
  orders.sort((a: OrderType, b: OrderType) => {
    const A = new Date(a.date_created._seconds);
    const B = new Date(b.date_created._seconds);

    if (A > B) return -1;
    if (A < B) return 1;
    return 0;
  });

  return orders;
}

// Sort orders by end of cycle
export function orderOrdersByEndOfCycle(orders: Array<OrderType>) {
  orders.sort((a: OrderType, b: OrderType) => {
    const A = a.end_of_cycle
      ? daysDifference(new Date(), new Date(a.end_of_cycle._seconds * 1000))
      : 100000;
    const B = b.end_of_cycle
      ? daysDifference(new Date(), new Date(b.end_of_cycle._seconds * 1000))
      : 100000;

    if (A > B) return 1;
    if (A < B) return -1;
    return 0;
  });

  return orders;
}
