// Recive a Date object and makes it string with the format YYYY-MM-DD
export const formatDate = (today: Date, getTime = true) => {
  const dateComponents = today.toLocaleDateString().split("/");

  if (dateComponents[1].length === 1) {
    dateComponents[1] = `0${dateComponents[1]}`;
  }

  const date = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`; // => 2022-12-05
  if (getTime) {
    const todaysTime = new Date().toLocaleTimeString();
    return `${date} ${todaysTime}`;
  }
  return `${date}`;
};

// Gets the difference in days between two dates
export function daysDifference(date1: Date, date2: Date) {
  if (!date1 || !date2) return 0;

  const diffTime = date2.getTime() - date1.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
