import styled from "styled-components";

export const SelectEl = styled.select`
  /* Default */
  min-width: 100px;
  height: 40px;
  border-radius: 4px;
  padding: 0px 15px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 200ms all;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--bs1);
  color: var(--gd);
  border: 1px solid var(--gd);
  background-color: #fff;

  &:hover {
    opacity: 0.9;
  }

  /* Sizes */
  &.sm {
    min-width: 80px;
    height: 30px;
    font-size: 13px;
  }
  &.lg {
    min-width: 120px;
    height: 45px;
    font-size: 17px;
  }
  &.fw {
    width: 100%;
  }
`;

export const x = 0;
