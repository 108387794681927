import { Button } from "@mui/material";
import { getAllSubscriptions } from "actions/SubscriptionsActions";
import LoadingComponent from "components/General/LoadingComponent";
import { useAppContext } from "contexts/AppContext/context";
import React from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { divs, texts } from "styleConstants";
import { SubscriptionType } from "types/Subscription";
import AddIcon from "@mui/icons-material/Add";

export default function Subscriptions() {
  const { appDispatch } = useAppContext();
  const [subscriptions, setSubscriptions] = React.useState<
    Array<SubscriptionType>
  >([]);
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  async function getAllSubscriptionsHandler() {
    // Get subscriptions
    const res = await getAllSubscriptions();
    if (!res.success) {
      toast.error(res.msg);
      return;
    }

    // Set states
    setSubscriptions(res.subscriptions);
    setLoading(false);
  }

  React.useEffect(() => {
    getAllSubscriptionsHandler();
  }, []);

  return (
    <div className={divs.pageContainer}>
      <div className={texts.pageTitle.container}>
        <h1 className={texts.pageTitle.h1}>Suscripciones</h1>
      </div>

      <div className="w-full flex justify-end mb-[5px]">
        <Link to="/private/subscriptions/add">
          <Button variant="contained" startIcon={<AddIcon />} size="small">
            Añadir Suscripción
          </Button>
        </Link>
      </div>

      {!loading ? (
        <div className="w-full grid grid-cols-3 gap-[30px] mt-[20px]">
          {subscriptions.map((s) => (
            <div
              key={s?.id}
              className="w-full shadow-bs3 rounded-md overflow-hidden mb-[10px] transition-all transition-200 hover:scale-[1.02] cursor-pointer"
              onClick={() => {
                // Set subscription
                appDispatch({ type: "setSubscriptionOnView", payload: s });
                // Go to view
                navigate("/private/subscriptions/view");
              }}
            >
              <div className="w-full h-[90px] flex relative items-center justify-center">
                <img
                  src="/imgs/squared-bg-blue.png"
                  alt="Bg"
                  className="w-full h-full object-cover absolute top-0 left-0"
                />
                <h1 className="text-black font-[600] text-[25px] z-10">
                  {s?.name || "Sin información"}
                </h1>
              </div>
              <div className="flex justify-between px-5 py-5">
                <div className="w-[45%]">
                  <p className="text-gd font-[500] text-[15px] text-center">
                    Cobro
                  </p>
                  <p className="text-[19px] font-[500] text-center">
                    {s?.monthly_fee ? `$${s?.monthly_fee}` : "Sin información"}
                  </p>
                </div>
                <div className="w-[45%]">
                  <p className="text-gd text-center font-[500] text-[15px]">
                    Créditos
                  </p>
                  <p className="text-center text-[19px] font-[500]">
                    {s?.credit_ammount || "Sin información"}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
}
