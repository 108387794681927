import React from "react";
import { PartnerType } from "types/Partner";
import { authReducer, AuthActionType } from "./reducer";

// Interfaces and types
export interface AuthStateType {
  partner: PartnerType | null;
}

export type AuthContextType = {
  authState: AuthStateType;
  authDispatch: React.Dispatch<AuthActionType>;
};

// Default State
const defaultState: AuthStateType = {
  partner: null,
};

// Create context
export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType
);

// Provider
export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Create auth state and dispatch
  const [authState, authDispatch] = React.useReducer(authReducer, defaultState);

  return (
    <AuthContext.Provider
      value={{
        authState,
        authDispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Use auth hook
export const useAuthContext = () => {
  return React.useContext(AuthContext);
};
